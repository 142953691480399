<template>
  <div :class="[listSoundModalClass, closeModalClass]" :style="modalHeight">
    <div class="sound-list__wrapper">
      <div class="sound-list__modal-bar-container" @click="soundListModal()">
        <div class="modal-bar"></div>
      </div>
      <div :class="modalDisplayToggleClass">
        <div class="sound-list__title-items" @click="soundListModal()">
          <h2 class="CF_heading-2">{{ historicSite.title }}</h2>
          <router-link
            :to="{
              name: 'Map',
            }"
            style="margin-left: auto"
          >
          </router-link>
        </div>
        <div class="sound-list__list-scroll" :style="listScrollHeight">
          <div
            v-for="(soundSite, index) in soundSites"
            :key="soundSite.id"
            class="select-chapter"
            :style="lastChapterMargin(index, soundSites.length - 1)"
            @click="audioScene(soundSite, index)"
          >
            <div class="sound-list__sound-img" @click="audioSwitchButton(soundSites.length)">
              <div class="img-container">
                <img :src="soundSite.photoURL" oncontextmenu="return false" />
                <div class="audio-play"></div>
              </div>
              <div class="string-container">
                <p class="CF_subtitle-medium-text">chapter.{{ index + 1 }}</p>
                <p class="CF_subtitle-medium-text">
                  {{ soundSite.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="sound-list__bottom-half">
          <div class="border-line"></div>
          <div class="description">
            <p>
              ぐるり寺は、日本の古代から続く伝統的な寺院で、その名前は周囲を一周することができる特徴的な回廊から名付けられました。寺院の中心には大きな本堂があり、その周囲には美しい日本庭園が広がっています。
            </p>
          </div>
          <div>
            <router-link
              :to="{
                name: 'ShowHistoricSite',
                params: { id: $route.params.id },
              }"
              style="width: 100%"
            >
              <span class="show-detail CF_button-bold-text"> 詳細を見る </span>
            </router-link>
          </div>
        </div> -->
      </div>
      <div :class="audioSceneClass">
        <div class="thumbnail-container">
          <div class="back">
            <img :src="BACK_IMG" @click="audioBackScene()" />
          </div>
          <div class="sound-list__sound-img" :class="thumbnailClass" @click="soundListModal()">
            <div class="img-container">
              <img :src="selectSoundSite.photoURL" />
              <div class="audio-play"></div>
            </div>
            <div class="string-container">
              <p class="CF_subtitle-medium-text">chapter.{{ selectSoundSiteIndex + 1 }}</p>
              <div v-if="isJapaneseLanguage">
                <p class="CF_subtitle-medium-text">{{ selectSoundSite.title }}</p>
              </div>
              <div v-else>
                <p class="CF_subtitle-medium-text">{{ selectSoundSite.englishTitle }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sound-site-content">
          <div
            v-if="selectSoundSite.englishDescription && selectSoundSite.englishTitle"
            class="language-buttons"
          >
            <button :class="japanese" class="CF_button-medium-text" @click="switchLanguage()">
              日本語
            </button>
            <button :class="english" class="CF_button-medium-text" @click="switchLanguage()">
              English
            </button>
          </div>
          <div class="sound-text">
            <div v-if="isJapaneseLanguage">
              <p class="CF_body-main" style="white-space: pre-line">
                {{ selectSoundSite.description?.replaceAll('\\n', '\n') }}
              </p>
            </div>
            <div v-else>
              <p class="CF_body-main" style="white-space: pre-line">
                {{ selectSoundSite.englishDescription?.replaceAll('\\n', '\n') }}
              </p>
            </div>
          </div>
          <div class="audio-control-container">
            <audio
              ref="audio"
              v-if="selectSoundSite.soundURL"
              :src="selectSoundSite.soundURL"
              controlslist="nodownload"
              oncontextmenu="return false"
              @play="isPlaying = true"
              @pause="isPlaying = false"
            ></audio>
            <div class="audio-controls">
              <div
                class="audio-prev"
                @click="prevAudioSound(selectSoundSiteIndex)"
                :class="audioSwitchClass"
              >
                <img :src="PLAY_IMG" class="prev-play arrow-right" />
                <img :src="PLAY_IMG" class="prev-play" />
              </div>
              <img
                v-if="!isPlaying"
                class="play"
                :src="PLAY_IMG"
                @click="audioSound(selectSoundSite)"
              />
              <img v-else class="pause" :src="PAUSE_IMG" @click="audioSound(selectSoundSite)" />
              <div
                class="audio-next"
                @click="nextAudioSound(selectSoundSiteIndex, soundSites.length - 1)"
                :class="audioSwitchClass"
              >
                <img :src="PLAY_IMG" class="next-play arrow-left" />
                <img :src="PLAY_IMG" class="next-play" />
              </div>
            </div>
            <div class="seek-bar">
              <input
                type="range"
                class="seek"
                v-model="currentTime"
                :max="duration"
                :style="seekBarStyle"
                @input="seekToTime"
              />
              <div class="view-time">
                <span class="view-current-time CF_capture-regular-text">{{
                  displayCurrentTime
                }}</span>
                <span class="view-duration CF_capture-regular-text">{{ displayDuration }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { PLAY_IMG, PAUSE_IMG, BACK_IMG } from '../../constants/img'
export default {
  components: {},
  props: {
    historicSite: {
      type: Object,
      require: true,
    },
    soundSites: {
      type: Array,
      require: true,
    },
    closeModalClass: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      PLAY_IMG: PLAY_IMG,
      PAUSE_IMG: PAUSE_IMG,
      BACK_IMG: BACK_IMG,
      audioSceneClass: 'audio-modal --none',
      modalDisplayToggleClass: 'overview',
      audioSwitchClass: 'audio-switch-button',
      listSoundModalClass: 'sound-list',
      thumbnailClass: '',
      selectSoundSite: {},
      selectSoundSiteIndex: 0,
      audio: null,
      isPlaying: false,
      seek: 0,
      duration: 0,
      currentTime: 0,
      comments: [],
      isJapaneseLanguage: true, // true: 日本語, false: English
      japanese: 'active',
      english: 'nonactive',
    }
  },
  watch: {
    closeModalClass: function (newVal) {
      if (newVal === 'close-modal' && this.audio) {
        this.audioReset(this.audio)
      }
    },
  },
  computed: {
    seekBarStyle() {
      if (this.currentTime === 0) {
        return {
          background: `linear-gradient(to right, #C4C4C4, #C4C4C4)`,
        }
      }

      return {
        background: `linear-gradient(to right, #1A1A1C 0%, #1A1A1C ${
          (this.currentTime / this.duration) * 100
        }%, #C4C4C4 ${(this.currentTime / this.duration) * 100}%, #C4C4C4 100%)`,
      }
    },
    listScrollHeight() {
      if (this.soundSites.length < 2) {
        return {
          height: '70px',
        }
      } else {
        return {
          height: '160px',
        }
      }
    },
    displayCurrentTime() {
      const totalSeconds = Math.floor(this.currentTime)
      const minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return `${minutes}:${seconds}`
    },
    displayDuration() {
      const totalSeconds = Math.floor(this.duration)
      const minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return `${minutes}:${seconds}`
    },
    modalHeight() {
      if (this.listSoundModalClass === 'sound-list --hidden') {
        // マップ下部の音声モーダルにスポットのタイトルのみ表示されている場合
        return {
          top: '81%',
        }
      } else {
        // マップ下部の音声モーダルに、音声一覧が表示されている場合
        if (this.modalDisplayToggleClass === 'overview') {
          return {
            top: '52%',
          }
        } else {
          // マップ下部の音声モーダルに、再生ボタンが表示されている場合
          return {
            top: '19%',
          }
        }
      }
    },
  },
  methods: {
    // 音声一覧を出す
    soundListModal() {
      if (this.listSoundModalClass !== 'sound-list') {
        this.listSoundModalClass = 'sound-list'
        this.closeModalClass = ''
      } else {
        this.listSoundModalClass = 'sound-list --hidden'
      }
    },
    audioSwitchButton(indexLength) {
      if (indexLength === 1) {
        this.audioSwitchClass = 'audio-switch-button --invisible'
      } else {
        this.audioSwitchClass = 'audio-switch-button'
      }
    },
    audioScene(soundSite, index) {
      if (this.audioSceneClass !== 'audio-modal') {
        this.modalDisplayToggleClass = 'overview --none'
        this.audioSceneClass = 'audio-modal'
        this.thumbnailClass = 'thumbnail'
        this.selectSoundSite = soundSite
        this.selectSoundSiteIndex = index
      } else {
        this.audioSceneClass = 'audio-modal --none'
        this.thumbnailClass = ''
      }
    },
    audioReset(audio) {
      if (audio) {
        audio.pause()
        this.audioEmpty()
      }
    },
    audioBackScene() {
      this.modalDisplayToggleClass = 'overview'
      this.audioSceneClass = 'audio-modal --none'
      this.thumbnailClass = ''
      this.audioReset(this.audio)
    },
    audioSound(selectSoundSite) {
      if (this.audio === null) {
        if (this.isJapaneseLanguage) {
          this.audio = new Audio(selectSoundSite.soundURL)
        } else {
          this.audio = new Audio(selectSoundSite.englishSoundURL)
        }
        // オーディオメタデータがロードされたときに実行されるコールバック
        this.audio.addEventListener('loadedmetadata', () => {
          this.duration = this.audio.duration
        })

        // オーディオの再生位置が変更されたときに実行されるコールバック
        this.audio.addEventListener('timeupdate', () => {
          if (this.audio) {
            this.currentTime = this.audio.currentTime
          }
        })

        this.audio.addEventListener('ended', () => {
          this.currentTime = 0
          this.duration = 0
          this.isPlaying = false
        })
      }
      if (!this.isPlaying) {
        this.isPlaying = true
        this.audio.play()
      } else {
        this.isPlaying = false
        this.audio.pause()
      }
    },
    seekToTime() {
      this.audio.currentTime = this.currentTime
      this.seek = (this.currentTime / this.duration) * 100
    },
    stopAudio() {
      if (this.audio) {
        this.audio.pause()
      }
    },
    audioEmpty() {
      this.isPlaying = false
      this.audio = null
      this.currentTime = 0
      this.duration = 0
      this.seek = 0
    },
    nextAudioSound(selectSoundSiteIndex, indexLength) {
      if (selectSoundSiteIndex !== indexLength) {
        this.selectSoundSite = this.soundSites[selectSoundSiteIndex + 1]
        this.selectSoundSiteIndex = selectSoundSiteIndex + 1
        this.audioReset(this.audio)
      }
    },
    prevAudioSound(selectSoundSiteIndex) {
      if (selectSoundSiteIndex !== 0) {
        this.selectSoundSite = this.soundSites[selectSoundSiteIndex - 1]
        this.selectSoundSiteIndex = selectSoundSiteIndex - 1
        this.audioReset(this.audio)
      }
    },
    getAverageRating() {
      let sum = 0
      for (let i = 0; i < this.comments.length; i++) {
        sum += Number(this.comments[i].rate)
      }
      return sum / this.comments.length
    },
    lastChapterMargin(index, indexLength) {
      if (index === indexLength) {
        return {
          marginBottom: '0px',
        }
      } else {
        return {
          marginBottom: '16px',
        }
      }
    },
    switchLanguage() {
      if (this.isJapaneseLanguage) {
        this.japanese = 'nonactive'
        this.english = 'active'
        this.isJapaneseLanguage = false
        this.audioReset(this.audio)
      } else {
        this.japanese = 'active'
        this.english = 'nonactive'
        this.isJapaneseLanguage = true
        this.audioReset(this.audio)
      }
    },
  },
  created() {
    firebase
      .firestore()
      .collection('comments')
      .where('historicSiteId', '==', this.$route.params.id)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.comments.push({ ...doc.data() })
        })
      })
  },
}
</script>

<style scoped lang="scss">
$sp: 767px;
@mixin sp {
  @media screen and (max-width: $sp) {
    @content;
  }
}
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
  color: black;
}

/* 音声一覧
---------------------------- */
$z-index: 1005;

.sound-list {
  position: fixed;
  bottom: 0px;
  z-index: $z-index;
  /* top: 19%; */
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100vw;
  // height: 100%;
  background: $gururi-base-color;
  padding: 29px 0 0 0;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  cursor: default;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);

  &:before {
    backdrop-filter: blur(10px);
  }

  &__wrapper {
    width: 91%;
    margin: 0 auto;
    position: relative;
  }

  &__modal-bar-container {
    z-index: $z-index;
    position: absolute;
    top: -29px;
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-bar {
      content: '';
      display: block;
      width: 128px;
      height: 5px;
      background-color: $bg-midium-gray;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      border-radius: 20px;
    }
  }

  &__title-items {
    display: flex;
    align-items: center;

    @include sp {
      justify-content: flex-start;
      padding: 26px 0 0;
    }
  }

  &__list-scroll {
    overflow: auto;
    margin-top: 36px;
  }

  &__sound-img {
    display: flex;
    justify-content: flex-start;
    width: 300px;
    .img-container {
      position: relative;
      height: 72px;
      width: 72px;
      img {
        margin-right: 12px;
        height: 72px;
        width: 72px;
        border-radius: 4px;
        object-fit: cover;
      }
      .audio-play {
        position: absolute;
        display: inline-block;
        margin: 19px;
        width: 35px;
        height: 35px;
        color: $text-white;
        background-color: rgba($text-dark, 0.5);
        border: 1px solid $text-white;
        border-radius: 50%;
      }
      .audio-play::before {
        position: absolute;
        top: 50%;
        left: 41%;
        transform: translateY(-50%);
        width: 0px;
        height: 0px;
        border: 0.3em solid transparent;
        border-left: 0.5em solid currentColor;
        box-sizing: border-box;
        content: '';
      }
    }

    .string-container {
      margin-top: 0px;
      margin-left: 12px;
    }

    div {
      text-align: left;
      display: flex;
      flex-direction: column;
    }
  }
  .audio-modal {
    position: fixed;
    bottom: 0px;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100vw;
    border-radius: 20px;
    transition: all 0.5s;
    cursor: default;
    .thumbnail-container {
      display: flex;
      padding-left: 7px;
      position: relative;
      top: 63px;
      gap: 13px;
      .back {
        display: flex;
        width: 32px;
        height: 72px;
        justify-content: center;
        align-items: center;
      }
    }
    .sound-site-content {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      position: relative;
      top: 80px;
      padding: 0px 32px;
      height: 80%;
      .language-buttons {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin-right: auto;
        .active {
          display: flex;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 1000px;
          background: var(--Alias-bg-white, #fffffe);
          color: $text-medium;
          border: 1px solid $border-midium;
        }
        .nonactive {
          display: flex;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 1000px;
          background: var(--Alias-bg-white, #fffffe);
          color: $text-disabled;
          border: 1px solid $border-light;
        }
      }
      .sound-text {
        overflow: scroll;
        text-align: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  .audio-control-container {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0 70px;
    .audio-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 35px;
      .audio-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 21px;
        .next-play {
          width: 21px;
          height: 21px;
        }
        .arrow-left {
          position: relative;
          left: 2px;
        }
      }
      .play {
        width: 40px;
        height: 40px;
      }
      .pause {
        width: 40px;
        height: 40px;
      }
      .audio-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        width: 34px;
        height: 21px;
        .prev-play {
          width: 21px;
          height: 21px;
        }
        .arrow-right {
          position: relative;
          left: 2px;
        }
      }
    }
    .seek-bar {
      .seek {
        -webkit-appearance: none;
        appearance: none;
        width: 221px;
        height: 4px;
        background: $bg-midium-gray;
        outline: none;
        border-radius: 2px;
      }
      .seek::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 9px;
        height: 9px;
        background: $text-dark;
        border-radius: 50%;
      }
      .seek::-moz-range-thumb {
        appearance: none;
        width: 9px;
        height: 9px;
        background: $text-dark;
        border-radius: 50%;
      }
      .view-time {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 188px;
        width: 100%;
        .view-current-time {
          font-size: 12px;
          line-height: 175%;
          letter-spacing: 0.08px;
          color: $text-dark;
        }
        .view-duration {
          font-size: 12px;
          line-height: 175%;
          letter-spacing: 0.08px;
          color: $text-dark;
        }
      }
    }
  }

  &__bottom-half {
    margin-top: 32px;
    overflow: auto;
    @include sp {
      height: 100%;
    }
    .border-line {
      width: 100%;
      height: 1px;
      background-color: $border-midium;
    }

    .description {
      margin: 17px 0px 23px;
      width: 100%;

      p {
        padding: 0px 8px;
        display: inline-block;
        text-align: left;
        line-height: 175%;
        letter-spacing: 0.08px;
      }
    }

    .show-detail {
      display: flex;
      width: 100%;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 1000px;
      background: $gururi-sub-color;
      border: none;
      color: $text-white;
    }
  }
}
/* .thumbnail {
  position: absolute;
  top: 64px;
  left: 52px;
} */
.--hidden {
  top: 81%;
}
.--none {
  display: none !important;
}
.--invisible {
  visibility: hidden;
}
.current-button-list-sound-site {
  position: absolute;
  z-index: 1004;
  top: -99px;
  right: 10px;
  transform: rotate(45deg);
}
.close-modal {
  display: none;
}
</style>

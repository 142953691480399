<template>
  <!-- 音声再生モーダル -->
  <b-modal id="modal-audio" centered hide-footer hide-header @hidden="$emit('close-modal')">
    <div class="listened-sound">
      <div class="listened-sound__wrapper">
        <p class="listened-sound__hide-button">
          <a class="text-base-color" @click="$emit('close-modal')"> &lt;&lt; 戻る </a>
        </p>
        <div class="listened-sound__sound-img">
          <img :src="soundSite.photoURL" oncontextmenu="return false" />
          <p>{{ soundSite.title }}</p>
        </div>
        <div class="listened-sound__sound-text">
          <p v-html="soundSite.description" />
        </div>
        <audio
          v-if="soundSite.soundURL"
          :src="soundSite.soundURL"
          controls
          controlslist="nodownload"
          oncontextmenu="return false"
        ></audio>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    soundSite: {
      type: Object,
      require: true,
    },
  },
  mounted() {
    this.$bvModal.show('modal-audio')
  },
}
</script>

<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
}

.text-base-color {
  color: $gururi-brand-color !important;
}
/* 音声再生モーダル
---------------------------- */
.listened-sound {
  padding: 17px 0;
  width: 100%;

  &__wrapper {
    width: 90%;
    margin: 0 auto;
  }

  &__hide-button {
    text-align: left;
  }

  &__sound-img {
    display: flex;
    justify-content: flex-start;
    margin: 18px 0px 28px;
    width: 90%;

    img {
      margin-right: 20px;
      border-radius: 10px;
      object-fit: cover;
      width: 90px;
      height: 72px;
    }

    p {
      margin-top: 8px;
    }
  }

  &__sound-text {
    overflow: scroll;
    height: 280px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  audio {
    margin-top: 25px;
    width: 100%;
  }
}
</style>

<template>
  <div class="m-3 pt-5 mb-3 pb-3">
    <!-- イベント -->
    <!-- <div class="title-container">
      <div class="home-vertical-bar" style=""></div>
      <div class="CF_heading-2"><span class="listen">イベント</span>で知る</div>
    </div>
    <div class="content">
      <img
        :src="OPEN_KANAZAWA_EVENT_IMG"
        @click="$router.push({ path: '/event-page' })"
        alt="金沢区イベントページ"
        class="kanazawa-event-img"
      />
      <p class="CF_subtitle-regular-text" style="white-space: pre-wrap">
        金沢区でデジタルスタンプラリー
      </p>
    </div> -->
    <!-- カテゴリ -->
    <div class="title-container">
      <div class="home-vertical-bar" style=""></div>
      <div class="CF_heading-2"><span class="listen">聞</span>いて知る</div>
    </div>
    <div class="contents">
      <div v-for="category in categories" :key="category.id">
        <router-link
          :to="{
            name: 'Category',
            params: { id: category.id },
          }"
          class="content"
        >
          <img :src="category.photoURL" class="category-img" oncontextmenu="return false" />
          <p class="CF_subtitle-regular-text" style="white-space: pre-wrap">
            {{ category.name.replaceAll('\\n', '\n') }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
import firebase from 'firebase'

export default {
  data() {
    return {
      categories: [],
    }
  },
  created() {
    const categoriesRef = firebase
      .firestore()
      .collection('categories')
      .orderBy('categoryOrder', 'desc')
    categoriesRef.get().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        if (!doc.data().logical_delete_flag) {
          this.categories.push({
            id: doc.id,
            ...doc.data(),
          })
        }
      })
    })
  },
}
</script>

<style scoped lang="scss">
.title-container {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  .home-vertical-bar {
    margin-right: 8px;
    width: 6px;
    height: 35px;
    background-color: $gururi-brand-color;
  }

  .listen {
    color: $gururi-brand-color;
  }
}

.contents {
  display: grid;
  gap: 16px;
  .content {
    display: grid;
    gap: 4px;
  }
}

.category-img {
  width: 100%;
  border-radius: 4px;
}
.kanazawa-event-img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $border-light;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}
</style>

import firebase from 'firebase'
import 'firebase/firestore'
import Vue from 'vue'
import store from '../src/store'
import { USER_ICON_IMG } from './constants/img'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// 以下ログイン機能関連
const initialUserState = {
  uid: '',
  email: '',
  displayName: '',
  photoURL: '',
  isEmailVerified: false,
  sex: '',
  address: '',
  birthday: '',
}
const $auth = Vue.observable({
  currentUser: {
    ...initialUserState,
  },
})
firebase.auth().onAuthStateChanged((user) => {
  let state
  if (user) {
    const { uid, email, emailVerified } = user
    state = {
      uid,
      email,
      displayName: '名前未設定',
      photoURL: USER_ICON_IMG,
      profile: 'よろしくお願いします。',
      isEmailVerified: false,
      sex: '',
      address: '',
      birthday: '',
    }
    const userDocRef = firebase.firestore().collection('users').doc(user.uid)
    userDocRef.get().then((doc) => {
      if (doc.exists) {
        Object.assign(state, doc.data())
        if (doc.data().isEmailVerified) {
          // サインイン
          Object.assign($auth.currentUser, state)
          console.log('ログイン')
        } else {
          if (emailVerified) {
            // 本登録
            state.isEmailVerified = true
            userDocRef
              .update({
                isEmailVerified: true,
              })
              .then(() => {
                Object.assign($auth.currentUser, state)
              })
          } else {
            Object.assign($auth.currentUser, initialUserState)
            console.log('確認メール未完了')
          }
        }
      } else {
        // 仮登録
        Object.assign(state, store.state.user)
        Object.assign($auth.currentUser, initialUserState)
        userDocRef.set(state)
        console.log('新規登録')
      }
    })
  } else {
    // サインアウト
    console.log('ログアウト')
    Object.assign($auth.currentUser, initialUserState)
  }
})
Vue.prototype.$auth = $auth

<script>
import firebase from 'firebase'
import PostmomentButton from '../components/parts/button/PostMomentButton.vue'

export default {
  components: {
    PostmomentButton,
  },
  async created() {
    this.momentList = await this.fetchMoment()
  },
  data() {
    return {
      momentList: [],
    }
  },
  methods: {
    async fetchMoment() {
      const firestore = firebase.firestore()
      const snapshot = await firestore
        .collection('moments')
        .where('logicalDeleteFlag', '==', false)
        .orderBy('createdAt', 'desc')
        .get()
      return snapshot.docs.map((doc) => {
        return {
          id: doc.data().id,
          title: doc.data().title,
          thumbnailPhotoBlob: doc.data().thumbnailPhotoBlob,
          userId: doc.data().userId,
          userName: doc.data().userName,
          userPhotoUrl: doc.data().userPhotoUrl,
        }
      })
    },
    moveShowMoment(momentId) {
      this.$router.push({ path: `/show-moment/${momentId}` })
    },
    moveUserPage(userId) {
      this.$router.push({ path: `/user/${userId}` })
    },
  },
}
</script>

<template>
  <div class="moment-list">
    <div>
      <p class="CF_heading-1 moment-list__moment-list-title">みんなの探訪記一覧</p>
    </div>

    <div class="moment-list__moment">
      <div v-for="moment in momentList" :key="moment.id" style="margin-bottom: 16px">
        <div @click="moveShowMoment(moment.id)">
          <img :src="moment.thumbnailPhotoBlob" alt="サムネイル画像" />
          <p class="CF_subtitle-bold-text moment-list__thumbnail-title">{{ moment.title }}</p>
        </div>
        <div @click="moveUserPage(moment.userId)" class="moment-list__user-info">
          <img :src="moment.userPhotoUrl" alt="ユーザー画像" class="moment-list__user-photo" />
          <p class="CF_subtitle-mini-semibold-text moment-list__user-name">{{ moment.userName }}</p>
        </div>
      </div>
    </div>

    <div class="moment-list__post-moment-btn">
      <PostmomentButton />
    </div>
  </div>
</template>

<style lang="scss" scoped>
img {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 4px;
  object-fit: cover;
}
.moment-list {
  margin: 94px 16px;
  text-align: left;
}
.moment-list__moment-list-title {
  color: $sumi-alpha-100;
}
.moment-list__moment {
  margin: 24px 16px 0;
}
.moment-list__thumbnail-title {
  margin: 8px 0;
  color: $sumi-alpha-100;
}
.moment-list__user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.moment-list__user-photo {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid $border-light;
}
.moment-list__user-name {
  color: $sumi-alpha-100;
}
.moment-list__post-moment-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 0px 16px 16px;
  background: $gururi-base-color;
}
</style>

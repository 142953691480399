<template>
  <div :style="styles">
    <ul class="parts-tabs">
      <li
        v-for="shopTypeTitle in shopTypeTitles"
        :key="shopTypeTitle.id"
        @click="changeTab(shopTypeTitle.typeId)"
        :class="{ active: isActive === shopTypeTitle.typeId }"
      >
        {{ shopTypeTitle.typeName }}
      </li>
    </ul>

    <ul class="contents">
      <div v-for="shopType in shopTypes" :key="shopType.id">
        <div v-if="isActive === shopType.typeId">
          <ShowCard
            :spotData="separateDataByTab(shopDataAndHistoricData, shopType.id)"
            dataType="shop"
          />
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import firebase from 'firebase'
import ShowCard from '../card/ShowCard.vue'

export default {
  components: {
    ShowCard,
  },
  props: {
    currentRouterId: {
      type: String,
      require: true,
    },
    shopData: {
      type: Array,
      require: true,
    },
    historicData: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      isActive: '',
      shopTypes: [],
      shopTypeTitles: [],
    }
  },
  async created() {
    const firestore = firebase.firestore()
    const shopTypesRef = firestore.collection('spotTypes')
    const categoriesRef = firestore.collection('categories').doc(this.currentRouterId)

    // spotTypesの全てのデータを取得。
    this.shopTypes = [...(await shopTypesRef.get()).docs.map((doc) => doc.data())]

    // タブごとにスポットを表示させるため、categoriesに紐づいているスポットタイプを取得
    const categoryHaveShopTypes = [...(await categoriesRef.get()).data().spotTypes]

    // spotTypesからcategoriesが持っているスポットタイプのデータのみ取得
    this.shopTypeTitles = this.shopTypes.filter((doc) => categoryHaveShopTypes.includes(doc.id))

    // 初期タブを設定
    this.isActive = this.shopTypeTitles[0].typeId
  },
  methods: {
    changeTab(activeTypeId) {
      this.isActive = activeTypeId
    },
    separateDataByTab(array, shopTypeId) {
      return array.filter((doc) => doc.shopTypesId === shopTypeId)
    },
  },
  computed: {
    styles() {
      return {
        '--shopTypeTitles': this.shopTypeTitles.length,
      }
    },
    shopDataAndHistoricData() {
      return this.shopData.concat(this.historicData)
    },
  },
}
</script>

<style scoped lang="scss">
li {
  list-style: none;
}
.parts-tabs {
  width: 100%;
  overflow: hidden;
  display: flex;
}
.parts-tabs li,
.parts-tabs label {
  padding: 10px 20px;
  transition: 0.3s;
  width: calc(100% / var(--shopTypeTitles));
  border-radius: 15px 15px 0 0;
  background: $sumi-50;
  border: 1px solid $gururi-base-color;
  border-bottom: none;
}
.parts-tabs li.active,
.parts-tabs :checked + label {
  background-color: $gururi-base-color;
  border-color: $gururi-brand-color;
  color: $gururi-brand-color;
}
.contents {
  width: 100%;
  margin-top: 16px;
  overflow: hidden;
}
</style>

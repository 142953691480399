var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-area_list_wrapper"},[_c('div',[_c('BaseBackIcon',{staticClass:"ms-3"}),_c('Header'),_c('p',{staticClass:"shop-area_list_ttl"},[_vm._v(_vm._s(_vm.category.name))])],1),_c('div',{staticClass:"shop-area_list_card"},_vm._l((_vm.shops),function(shop){return _c('div',{key:shop.id},[_c('div',{staticClass:"shop-area_list_card_thumbnail"},[_c('img',{staticClass:"shop-area_list_card_thumbnail_img",attrs:{"src":shop.photoURL,"alt":"店舗のサムネイル画像"}})]),_c('div',{staticClass:"shop-area_list_card_textbox"},[_c('div',{staticClass:"shop-area_list_card_textbox_shopname"},[_vm._v(" "+_vm._s(shop.title)+" ")]),_c('div',{staticClass:"shop-area_list_card_textbox_shop-address"},[_vm._v(" "+_vm._s(shop.prefecture + shop.city + shop.address)+" ")]),_c('div',{staticClass:"shop-area_list_card_textbox_overviewtext"},[_vm._v(" "+_vm._s(shop.detail)+" ")])]),_c('div',{staticClass:"shop-area_list_card_footer"},[_c('router-link',{staticClass:"shop-area_list_card_footer_btn map",attrs:{"to":{
            name: 'Shop',
            params: { id: shop.id },
          }}},[_vm._v("マップを見る")]),_c('router-link',{staticClass:"shop-area_list_card_footer_btn info",attrs:{"to":{
            name: 'ShowShop',
            params: { id: shop.id },
          }}},[_vm._v("情報を見る")])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
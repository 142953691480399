<template>
  <div>
    <BeforeSignUp v-if="$route.name == 'BeforeSignUp'" />

    <!-- 音声再生モーダル -->
    <Audio
      v-if="soundSiteForModal.id"
      :soundSite="soundSiteForModal"
      @close-modal="soundSiteForModal = {}"
    />

    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      :options="{ zoomControl: false }"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @click="closeModal"
      style="height: auto; position: fixed; top: 6vh; left: 0px; bottom: 0vh"
    >
      <l-tile-layer :url="url" :attribution="attribution" :options="tileLayerOptions" />
      <l-polyline
        v-for="(routePoints, routeId) in routeShapePoints"
        :key="routeId"
        :lat-lngs="routePoints"
        :color="routeColors[routeId]"
        :weight="6"
      />

      <!-- 史跡マーカー -->
      <historic-marker
        :historicSites="historicSites"
        :icon="{
          iconSize: changeHistoricIconSize,
        }"
        @compareCurrentAndVisited="compareCurrentAndVisited"
        @marker-click="openListSoundSite"
      />

      <!-- 店舗マーカー -->
      <div v-for="shop in shops" :key="shop.id">
        <shop-marker
          :shop="shop"
          :icon="{
            iconSize: changeIconSize,
          }"
          @compareCurrentAndVisited="compareCurrentAndVisited"
        />
      </div>

      <!-- 音声マーカー -->
      <div v-for="soundSite in soundSites" :key="soundSite.id">
        <l-marker
          v-if="soundSite.latLng"
          :lat-lng="[soundSite.latLng.latitude, soundSite.latLng.longitude]"
          :icon="soundIcon"
          @click="getSoundSitePosition(soundSite)"
          ref="soundSiteMarker"
        >
          <l-popup>
            <div class="sound-popup">
              <img
                :src="soundSite.photoURL"
                oncontextmenu="return false"
                @click="listenSound(soundSite)"
              />
              <div class="sound-popup-text">
                <h3>{{ soundSite.title }}</h3>
                <a @click="listenSound(soundSite)">音声を聴く &gt;&gt;</a>
              </div>
            </div>
          </l-popup>
        </l-marker>
      </div>

      <!-- 現在地マーカー -->
      <l-marker :lat-lng="currentCenter" :icon="currentIcon"> </l-marker>
      <l-control position="topright">
        <div class="markers">
          <div style="margin-bottom: 12px">
            <img :src="LAYER_IMG" height="32px" @click="changeMap" />
          </div>
          <div>
            <img
              :src="CURRENT_IMG"
              height="28px"
              style="transform: rotate(45deg)"
              @click="getCurrentPosition"
            />
          </div>
        </div>
      </l-control>

      <!-- <l-control position="topleft">
        <div class="event-img" :class="showEventImg ? '' : 'display-none'">
          <img
            :src="OPEN_KANAZAWA_EVENT_IMG"
            @click="$router.push({ path: '/event-page' })"
            alt="金沢区イベントページ"
            width="246px"
          />
          <img
            :src="CLOSE_BG_WHITE_IMG"
            @click="showEventImg = false"
            alt="閉じるボタン"
            class="close-bg-white"
          />
        </div>
      </l-control> -->
    </l-map>

    <!-- 音声リスト -->
    <ListSoundSite
      v-if="$route.name == 'HistoricSite'"
      :historicSite="historicSite"
      :soundSites="soundSites"
      @get-position="getSoundSitePosition"
      @open-popup="openSoundSitePopup"
      @listen-sound="listenSound"
      :key="$route.fullPath"
      :closeModalClass="closeModalClass"
      ref="listSoundSite"
    />
  </div>
</template>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControl, LPolyline } from 'vue2-leaflet'
import firebase from 'firebase'
import fetchJsonp from 'fetch-jsonp'
import Audio from '../components/elements/Audio.vue'
import ShopMarker from '../components/elements/ShopMarker.vue'
import HistoricMarker from '../components/elements/HistoricMarker.vue'
import BeforeSignUp from '../components/modals/BeforeSignUpModal.vue'
import ListSoundSite from '../components/elements/ListSoundSite.vue'
import { LAYER_IMG, CURRENT_IMG, USER_ICON_IMG, CLOSE_BG_WHITE_IMG } from '../constants/img'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControl,
    Audio,
    ListSoundSite,
    BeforeSignUp,
    ShopMarker,
    HistoricMarker,
    LPolyline,
  },
  data() {
    return {
      LAYER_IMG: LAYER_IMG,
      CURRENT_IMG: CURRENT_IMG,
      USER_ICON_IMG: USER_ICON_IMG,
      CLOSE_BG_WHITE_IMG: CLOSE_BG_WHITE_IMG,
      firestore: firebase.firestore(),
      // 初期zoomレベル
      zoom: 16,
      // 使用する地図のレイヤー
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // 地図の参照  '&copy INCREMENT P CORPORATION <a href="https://api-auth-pre.mapfan.com/terms/mfapi_terms.pdf">利用規約</a>'
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // maxZoomを18→21
      tileLayerOptions: {
        maxNativeZoom: 18,
        maxZoom: 21,
      },
      //ルート経路データ
      routePointslatLng: [],
      //ルート描画データ
      routeShapePoints: [],
      //ルートの色データ
      routeColors: [],
      // 現在値アイコン
      currentIcon: L.icon({
        iconUrl: '/images/current-icon.png',
        iconSize: [25],
        className: 'current-icon',
      }),
      // 音声アイコン
      soundIcon: L.icon({
        iconUrl: '/images/sound-icon.png',
        iconSize: [40],
      }),
      // props史跡用
      historicSite: {},
      // 音声マーカー用、props音声一覧用
      soundSites: [],
      // 音声再生用
      soundSite: {},
      // 音声モーダルで表示する音声データ
      soundSiteForModal: {},
      // モーダルを閉じるためのクラス
      closeModalClass: '',
      // モーダルを開くためのクラス
      openModalClass: '',
      showEventImg: true,
    }
  },
  created() {
    if (this.$route.name == 'HistoricSite') {
      this.getSoundSites(this.$route.params.id)
    }
    if (this.$route.name == 'Shop') {
      firebase
        .firestore()
        .collection('shops')
        .doc(this.$route.params.id)
        .get()
        .then((snapshot) => {
          this.$store.commit('setCenter', {
            lat: snapshot.data().latLng.latitude,
            lng: snapshot.data().latLng.longitude,
          })
        })
    }

    //Mapfunコース作成
    this.createCourse()
  },
  computed: {
    // 地図の中心
    center() {
      return this.$store.state.center
    },
    // 現在地マーカー
    currentCenter() {
      return [this.$position.latitude, this.$position.longitude]
    },
    // 史跡一覧
    historicSites() {
      return this.$store.state.historicSites
    },
    // ショップ一覧
    shops() {
      return this.$store.state.shops
    },
    // zoomによってマップ上の店舗マーカーのサイズを変更
    changeIconSize() {
      return [0, this.zoom * 1.7]
    },
    // zoomによってマップ上の史跡マーカーのサイズを変更
    changeHistoricIconSize() {
      return [0, this.zoom * 2.5]
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == 'HistoricSite') {
        this.getSoundSites(to.params.id)
      }
      if (from.name == 'HistoricSite') {
        this.soundSites = []
      }
    },
  },
  beforeDestroy() {
    if (this.$refs.listSoundSite) {
      this.$refs.listSoundSite.stopAudio()
      this.$refs.listSoundSite.audioEmpty()
    }
  },
  methods: {
    closeModal() {
      if (this.closeModalClass === '') {
        this.closeModalClass = 'close-modal'
      }
    },
    openListSoundSite() {
      this.closeModalClass = ''
      if (this.$refs.listSoundSite) {
        this.$refs.listSoundSite.stopAudio()
        this.$refs.listSoundSite.audioEmpty()
      }
    },
    // ユーザーの現在地と比較
    compareCurrentAndVisited(spotId) {
      // ユーザーの現在地の認識がブロックされた場合
      if (!this.$store.state.isCurrentPosition) {
        alert('位置情報の使用を許可してください')
        return
      }

      const isRegister = confirm('訪問を記録しますか？\n※訪問記録は削除できません。')
      if (isRegister) {
        let visitedId = ''
        const LATITUDE = 0.000008983148616 * 80 // 1mあたりの緯度
        const LONGITUDE = 0.000010966382364 * 80 // 1mあたりの軽度
        const currentLat = this.currentCenter[0]
        const currentLog = this.currentCenter[1]
        const allSpots = [...this.$store.state.shops, ...this.$store.state.historicSites]

        allSpots.forEach((doc) => {
          if (
            currentLat <= doc.latLng.latitude + LATITUDE &&
            currentLat >= doc.latLng.latitude - LATITUDE &&
            currentLog <= doc.latLng.longitude + LONGITUDE &&
            currentLog >= doc.latLng.longitude - LONGITUDE
          ) {
            // 訪問した史跡と一致しているかチェック
            if (spotId == doc.id) {
              visitedId = doc.id
            }
          }
        })
        if (this.$store.state.visitedSpotIds.map((doc) => doc.visitedSpotId).includes(visitedId)) {
          // すでに訪れていたスポットはfirestoreに追加登録しない。
          return
        }
        if (visitedId) {
          // ユーザーが店舗の範囲以内に入れば、訪問済みスタンプを表示
          try {
            // ユーザーコレクションに訪れたスポットを登録
            const visitedHistoryCollectionRef = this.firestore
              .collection('users')
              .doc(this.$auth.currentUser.uid)
              .collection('visitedHistory')
            const visitedHistoryTime = firebase.firestore.FieldValue.serverTimestamp()
            visitedHistoryCollectionRef.add({
              visitedSpotId: visitedId,
              createdAt: visitedHistoryTime,
            })
            this.$store.commit('setVisitedSpotIds', [
              ...this.$store.state.visitedSpotIds,
              {
                visitedSpotId: visitedId,
                visitedHistoryTime: visitedHistoryTime,
              },
            ])

            // trueの場合はshop, falseの場合は史跡を判定する
            const isCurrentShop = this.$store.state.shops.some((shop) => shop.id === visitedId)
            if (isCurrentShop) {
              // 店舗に訪れたユーザーをfirestoreに登録
              const visitedShopCollectionRef = this.firestore
                .collection('shops')
                .doc(visitedId)
                .collection('visitedUser')
              visitedShopCollectionRef.add({
                visitedUserId: this.$auth.currentUser.uid,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              })
            } else {
              // 史跡に訪れたユーザーをfirestoreに登録
              const visitedHistoricSitesCollectionRef = this.firestore
                .collection('historicSites')
                .doc(visitedId)
                .collection('visitedUser')
              visitedHistoricSitesCollectionRef.add({
                visitedUserId: this.$auth.currentUser.uid,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              })
            }
          } catch (error) {
            console.error(error)
          }
          alert('訪問しました')
        } else {
          // ユーザーが範囲以外にいればアラートを表示
          alert('スポットに近づいてください')
        }
      }
    },
    //zoom時にzommレベルをデータに格納(必須)
    zoomUpdated(zoom) {
      this.zoom = zoom
    },
    // 地図移動時に中心位置をデータに格納(必須)
    centerUpdated(center) {
      this.$store.commit('setCenter', {
        lat: center.lat,
        lng: center.lng,
      })
    },
    async getSoundSites(historicSiteId) {
      const historicSiteRef = firebase.firestore().collection('historicSites').doc(historicSiteId)
      const soundSitesRef = historicSiteRef.collection('soundSites')

      await historicSiteRef.get().then((snapshot) => {
        const data = snapshot.data()
        this.historicSite = data
        this.$store.commit('setCenter', {
          lat: data.latLng.latitude,
          lng: data.latLng.longitude,
        })
      })

      const soundSites = []
      await soundSitesRef.get().then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          soundSites.push({
            id: doc.id,
            ...doc.data(),
          })
        })
      })
      // 史跡の音声を先頭に持ってくる
      this.soundSites = soundSites
        .filter((a) => !a.latLng)
        .concat(soundSites.filter((b) => b.latLng))
    },
    // 音声ガイドの位置に移動
    getSoundSitePosition(soundSite) {
      this.$store.commit('setCenter', {
        lat: soundSite.latLng.latitude,
        lng: soundSite.latLng.longitude,
      })
    },
    // 現在地取得
    getCurrentPosition() {
      this.$store.commit('setCenter', {
        lat: this.$position.latitude,
        lng: this.$position.longitude,
      })
    },
    // 音声モーダル開く
    listenSound(soundSite) {
      this.soundSiteForModal = soundSite
    },
    // 音声マーカーのポップアップを開く
    openSoundSitePopup(index) {
      this.$refs.soundSiteMarker[index].mapObject.openPopup()
    },
    zeroPadding(number, digit) {
      return (Array(digit).join('0') + number).slice(-digit)
    },
    changeMap() {
      if (this.url == 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png') {
        // 以下mapfan
        const authId = process.env.VUE_APP_MAPFUN_AUTH_ID
        const authUrl = process.env.VUE_APP_MAPFUN_AUTH_URL
        const date = new Date()
        const now =
          this.zeroPadding(date.getFullYear(), 4) +
          this.zeroPadding(date.getMonth() + 1, 2) +
          this.zeroPadding(date.getDate(), 2) +
          this.zeroPadding(date.getHours(), 2) +
          this.zeroPadding(date.getMinutes(), 2) +
          this.zeroPadding(date.getSeconds(), 2)

        fetchJsonp(authUrl + 'appid=' + authId + '&date=' + now)
          .then((res) => {
            return res.json()
          })
          .then((json) => {
            this.url =
              process.env.VUE_APP_MAPFUN_MAP_URL +
              'key=' +
              json.key +
              '&tilematrix=EPSG:3857:{z}&tilecol={x}&tilerow={y}&mapstyle=antique_sp&'
          })
          .catch((res) => {
            console.log('失敗' + res)
          })
      } else {
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      }
    },
    //ルートの表示
    async createCourse() {
      try {
        const firestore = firebase.firestore()
        const courseRef = firestore.collection('courses')
        // ドキュメントIDを取得し、史跡IDを取得
        courseRef
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              if (doc.data().logicalDeleteFlag === false) {
                const historicSiteIdList = []
                const waypoints = doc.data().waypoints
                const routeColor = doc.data().color

                //経由地点すべてのhistoricSiteIdを取得
                waypoints.forEach(async (waypoint) => {
                  historicSiteIdList.push(waypoint.historicSiteId)
                })
                const viaPointsLatLng = await this.fetchHistoricSiteLatLng(historicSiteIdList)
                await this.createRouteShape(viaPointsLatLng, routeColor)
              }
            })
          })
          .catch((error) => {
            console.error('Error getting documents: ', error)
          })
      } catch (error) {
        console.error('エラーが発生しました:', error)
      }
    },
    async fetchHistoricSiteLatLng(historicSiteIdList) {
      const routePointslatLng = []
      for (const historicSiteId of historicSiteIdList) {
        const firestore = firebase.firestore()
        const historicSiteRef = firestore.collection('historicSites').doc(historicSiteId)
        const historicSiteSnapshot = await historicSiteRef.get()
        const historicSiteData = historicSiteSnapshot.data()
        routePointslatLng.push([historicSiteData.latLng._lat, historicSiteData.latLng._long])
      }
      return routePointslatLng
    },
    async createRouteShape(routePointslatLng, routeColor) {
      const viaPoints = routePointslatLng
        .slice(1, -1)
        .map((point) => point[1] + ',' + point[0])
        .join('|')

      const authId = process.env.VUE_APP_MAPFUN_AUTH_ID
      const authUrl = process.env.VUE_APP_MAPFUN_AUTH_URL
      const routeUrl = process.env.VUE_APP_MAPFUN_ROUTE_MAP_URL
      const date = new Date()
      const now =
        this.zeroPadding(date.getFullYear(), 4) +
        this.zeroPadding(date.getMonth() + 1, 2) +
        this.zeroPadding(date.getDate(), 2) +
        this.zeroPadding(date.getHours(), 2) +
        this.zeroPadding(date.getMinutes(), 2) +
        this.zeroPadding(date.getSeconds(), 2)

      fetchJsonp(authUrl + 'appid=' + authId + '&date=' + now)
        .then((res) => {
          return res.json()
        })
        .then((json) => {
          const route =
            routeUrl +
            'key=' +
            json.key +
            '&start=' +
            routePointslatLng[0][1] +
            ',' +
            routePointslatLng[0][0] +
            (routePointslatLng.length > 3 ? '&via=' + viaPoints : '') +
            '&destination=' +
            routePointslatLng[routePointslatLng.length - 1][1] +
            ',' +
            routePointslatLng[routePointslatLng.length - 1][0] +
            '&callback=jsonp_callback_function' +
            '&priority=3'

          //ルート検索api取得
          fetchJsonp(route)
            .then((res) => {
              return res.json()
            })
            .then((data) => {
              // ルート形状データループ
              data.shape.forEach((routeData) => {
                const routePoints = []
                // 緯度経度点列ループ
                routeData.shapePoints.forEach((shapePoint) => {
                  routePoints.push([shapePoint.lat, shapePoint.lon])
                })
                this.routeShapePoints.push(routePoints)
                this.routeColors.push(routeColor)
              })
            })
            .catch((res) => {
              console.log('ルート検索失敗' + res)
            })
        })
        .catch((res) => {
          console.log('認証失敗' + res)
        })
    },
  },
}
</script>
<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
}

/* マップ
---------------------------- */
.sound-popup {
  display: flex;
  p {
    color: $gururi-brand-color;
  }
  img {
    width: 70px;
    border-radius: 10px;
    object-fit: cover;
  }
  .sound-popup-text {
    margin-left: 15px;
  }
}
.markers {
  position: fixed;
  top: 77px;
  right: 16px;
}
.event-img {
  position: fixed;
  top: 77px;
  left: 16px;
}
.close-bg-white {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
}
.display-none {
  display: none;
}
.topright {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  .layer {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
  }
  .current {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
  }
}
</style>

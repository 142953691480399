<template>
  <div class="m-3 mb-3 pb-3 mt-5 pt-5">
    <Header class="pb-3" />
    <UserTermContent class="text-start" />
  </div>
</template>

<script>
import Header from '../components/layouts/Header.vue'
import UserTermContent from '../constants/content/UserTermContent.vue'

export default {
  components: {
    Header,
    UserTermContent,
  },
}
</script>

<template>
  <b-button
    :to="{
      name: toName,
      params: { id: params },
    }"
    :class="'border-0 w-50 ' + propsClass"
  >
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      require: true,
    },
    toName: {
      type: String,
      require: true,
    },
    params: {
      type: String,
      require: true,
    },
    propsClass: {
      type: String,
      require: true,
    },
  },
}
</script>

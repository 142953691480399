<template>
  <div class="m-3 pt-5 mb-3 pb-3">
    <BaseBackIcon />
    <div class="contents">
      <img
        :src="KANAZAWAKU_THUMBNAIL_IMG"
        class="photo"
        oncontextmenu="return false"
        alt="コンテンツタイトル画像"
      />
      <div class="event-about-title">イベントについて</div>
      <div class="event-description">
        <p class="CF_body-sub">
          2023年3月8日（金）から3月25日（月）まで、デジタルスタンプラリーを開催します！ぜひ、ご参加ください。
        </p>
        <p class="CF_body-sub application-method">〜応募方法〜</p>
        <P class="CF_body-sub">
          ① スポットに到着したら、GURURIの地図画面を開いて訪問スタンプを押そう<br />
          ② 各コースのスポットの内4つ以上訪問すると、豪華賞品に応募することができます！<br />
          ※イベント開催期間：2024/3/8 12:00〜3/25 23:59<br />
          ※応募には「GURURI」へのアカウント登録が必要になります。 <br />
          ※事業概要は<a
            href="https://www.city.yokohama.lg.jp/kanazawa/kurashi/kyodo_manabi/kyodo_shien/chiiki/saikuru.html"
            >こちら</a
          >(横浜市HP)
        </P>
      </div>

      <!-- コース選択ボタン -->
      <div class="course-category">
        <button
          @click="changeCourse('歴史の道コース')"
          class="course-category__button CF_button-medium-text"
          :class="{ inactive: selectedCourse !== '歴史の道コース' }"
        >
          歴史の道
        </button>
        <button
          @click="changeCourse('お買い物と海を楽しむコース')"
          class="course-category__button CF_button-medium-text"
          :class="{ inactive: selectedCourse !== 'お買い物と海を楽しむコース' }"
        >
          お買い物と海
        </button>
        <button
          @click="changeCourse('シーサイドコース')"
          class="course-category__button CF_button-medium-text"
          :class="{ inactive: selectedCourse !== 'シーサイドコース' }"
        >
          シーサイド
        </button>
        <button
          @click="changeCourse('富岡の緑を感じるコース')"
          class="course-category__button CF_button-medium-text"
          :class="{ inactive: selectedCourse !== '富岡の緑を感じるコース' }"
        >
          富岡の緑を感じる
        </button>
        <button
          @click="changeCourse('釜利谷歴史散策コース')"
          class="course-category__button CF_button-medium-text"
          :class="{ inactive: selectedCourse !== '釜利谷歴史散策コース' }"
        >
          釜利谷歴史散策
        </button>
      </div>

      <!-- コース経由地点一覧 -->
      <section class="course-list">
        <img :src="this.currentCourseTitleImg" class="course-list__title" />
        <div>
          <div class="course-list__container">
            <div
              v-for="waypoint in currentCourseWaypoints"
              :key="waypoint.id"
              class="course-list__container__waypoint"
            >
              <img
                v-if="waypoint.isVisitedSpot"
                :src="VISITED_HANKO_BLUE_IMG"
                class="visited-hanko"
                alt="訪問スタンプ"
              />
              <img
                :src="waypoint.photoURL"
                :class="classVisitedSpots(waypoint.isVisitedSpot)"
                alt="経由地点画像"
              />
              <p class="CF_subtitle-regular-text">{{ waypoint.title }}</p>
            </div>
          </div>
        </div>
        <button
          class="course-list__form"
          :class="isCurrentCourseSubmit ? 'possible-submit' : 'impossible-submit'"
        >
          <h5
            @click="submitApplicationForm"
            class="CF_subtitle-bold-text"
            :class="isCurrentCourseSubmit ? '' : 'impossible-submit-text'"
          >
            このコースに応募する
          </h5>
        </button>
      </section>

      <section class="how-visited">
        <h1 class="CF_heading-2">訪問済みにするには？</h1>
        <p class="CF_subtitle-medium-text how-visited__step">
          ①目的地のスポットに着いたら、GURURIの地図画面を開いてください。
        </p>
        <img
          :src="EVENTPAGE_VISITED1_IMG"
          style="width: 265px"
          alt="訪問済みにするための説明画像1"
        />
        <p class="CF_subtitle-medium-text how-visited__step">
          ②スポットのアイコンをタップすると、アイコンの上に吹き出しが表示されます。
        </p>
        <img
          :src="EVENTPAGE_VISITED2_IMG"
          style="width: 281px"
          alt="訪問済みにするための説明画像2"
        />
        <div class="how-visited__step">
          <p class="CF_subtitle-medium-text">
            ③オレンジ色の「訪問スタンプを押す」をタップすると、訪問済みにすることができます。
          </p>
          <p class="CF_subtitle-medium-text how-visited__step__3">
            ※訪問済みにならないエラーが発生した場合は、<br />
            <a href="https://gururi-guide.notion.site/1bebd4066a7443f692d67b51e0c76878"
              >「訪問スタンプが押せません」</a
            >のヘルプページをご参照ください。<br />
            ※アカウント登録ができないなど、その他お困りの場合は<a
              href="https://gururi-guide.notion.site/acf503d9033d4c65adf13086f8a812b8"
              >ヘルプセンター</a
            >をご参照ください。
          </p>
        </div>
        <img
          :src="EVENTPAGE_VISITED3_IMG"
          style="width: 281px"
          alt="訪問済みにするための説明画像3"
        />
      </section>
    </div>
  </div>
</template>

<script>
import {
  VISITED_HANKO_BLUE_IMG,
  KANAZAWAKU_THUMBNAIL_IMG,
  EVENTPAGE_COURSE_TITLE_BACKGROUND_IMG,
  EVENTPAGE_VISITED1_IMG,
  EVENTPAGE_VISITED2_IMG,
  EVENTPAGE_VISITED3_IMG,
} from '../constants/img'
import firebase from 'firebase'
import { categoryDesc } from '../constants/const'

export default {
  data() {
    return {
      categories: [],
      VISITED_HANKO_BLUE_IMG: VISITED_HANKO_BLUE_IMG,
      KANAZAWAKU_THUMBNAIL_IMG: KANAZAWAKU_THUMBNAIL_IMG,
      EVENTPAGE_COURSE_TITLE_BACKGROUND_IMG: EVENTPAGE_COURSE_TITLE_BACKGROUND_IMG,
      EVENTPAGE_VISITED1_IMG: EVENTPAGE_VISITED1_IMG,
      EVENTPAGE_VISITED2_IMG: EVENTPAGE_VISITED2_IMG,
      EVENTPAGE_VISITED3_IMG: EVENTPAGE_VISITED3_IMG,
      courseList: [],
      currentCourseId: '',
      currentCourseWaypoints: [],
      currentCourseTitleImg: '',
      isCurrentCourseSubmit: false,
      selectedCourse: '歴史の道コース',
      firebase: firebase.firestore(),
      eventFormUrl:
        'https://shinsei.city.yokohama.lg.jp/cu/141003/ea/residents/procedures/apply/eede4f8f-3620-4de6-9d1e-d0a3616d5eae/start',
    }
  },
  async created() {
    this.courseList = await this.getKanazawakuCourseInfo()
    this.changeCourse('歴史の道コース') //コース一覧の初期セット
  },
  methods: {
    // コース情報を取得
    async getKanazawakuCourseInfo() {
      try {
        const firestore = firebase.firestore()
        const categoriesSubRef = firestore
          .collection('categories')
          .doc(categoryDesc.kanazanwa.id)
          .collection('subCategories')

        const querySnapshot = await categoriesSubRef.get()
        // コースの経由地点情報を取得
        const waypointList = querySnapshot.docs.map((doc) => doc.data())
        // 経由地点の情報を取得
        const historiesData = await Promise.all(
          waypointList.map(async (doc) => {
            return Promise.all(
              doc.order.map(async (historicSiteId) => {
                return await this.getHistoricSites(firestore, historicSiteId)
              })
            )
          })
        )
        // コースの経由地点情報を整形
        return waypointList.map((doc, index) => {
          return {
            id: doc.id,
            name: doc.name,
            titleImg: doc.titleImg,
            waypointList: historiesData[index],
          }
        })
      } catch (error) {
        console.error('エラーが発生しました:', error)
      }
    },
    async getHistoricSites(firestore, historicSiteId) {
      const historicSiteRef = firestore.collection('historicSites').doc(historicSiteId)
      try {
        const querySnapshot = await historicSiteRef.get()
        return {
          title: querySnapshot.data().title,
          photoURL: querySnapshot.data().photoURL,
          isVisitedSpot: this.markVisitedSpots(querySnapshot.id),
        }
      } catch (error) {
        console.error('Error getting documents: ', error)
        return null
      }
    },
    async changeCourse(courseTitle) {
      // ログインしている場合、応募可能なコースを判定
      if (this.$auth.currentUser.uid) {
        await this.canSubmitApplicationForm()
      }
      const course = this.courseList.find((course) => course.name === courseTitle)
      this.currentCourseId = course.id
      this.currentCourseWaypoints = course.waypointList
      this.currentCourseTitleImg = course.titleImg
      this.isCurrentCourseSubmit = course.isSubmit
      this.selectedCourse = courseTitle //ボタンの活性・非活性
    },
    // 訪問済みスポットを判定
    markVisitedSpots(historyId) {
      let isVisitedSpot = false
      this.$store.state.visitedSpotIds.forEach((doc) => {
        if (doc.visitedSpotId === historyId) {
          isVisitedSpot = true
        }
      })
      return isVisitedSpot
    },
    // 訪問済みスポットのスタイルを切り替え
    classVisitedSpots(isVisitedSpot) {
      return isVisitedSpot
        ? 'course-list__container__waypoint__visited'
        : 'course-list__container__waypoint__not-visited'
    },
    // 応募フォームを送信
    submitApplicationForm(e) {
      // 応募可能なコースでない場合、イベントをキャンセル
      if (!this.isCurrentCourseSubmit) {
        e.preventDefault()
        return
      }
      window.open(this.eventFormUrl, '_blank')
    },
    // 応募可能なコースを判定
    async canSubmitApplicationForm() {
      const NEED_SUBMIT_COUNT = 4
      this.courseList = this.courseList?.map((course) => {
        let count = 0
        // isVisitedSpotがtrueの数をカウント
        course.waypointList.forEach((waypoint) => {
          if (waypoint.isVisitedSpot) {
            count++
          }
        })

        return {
          ...course,
          isSubmit:
            // 訪問済みのスポットが４つ以上の場合
            count >= NEED_SUBMIT_COUNT ? true : false,
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
h5 {
  margin: 0;
}

a {
  color: $semantic-info;
}

.contents {
  display: grid;
  gap: 16px;
  .photo {
    width: 100%;
    border-radius: 4px;
  }
  .event-about-title {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid $kanazawaku;
  }
  .event-description {
    padding: 8px;
    text-align: left;
    .application-method {
      text-align: center;
    }
  }

  .course-category {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 16px 12px;
    margin: 20px auto;
    width: 100%;
    justify-content: center;
    &__button {
      position: relative;
      padding: 8px 10px 8px 10px;
      width: 100%;
      height: 34px;
      text-align: left;
      background-color: $gururi-base-color;
      color: $text-dark;
      border: 1.5px solid $kanazawaku;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &__button::after {
      content: '＞';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $kanazawaku;
    }
  }

  .inactive {
    color: rgba($text-light, 0.5);
    border: 1.5px solid $border-midium;
    box-shadow: none;
  }
  .inactive::after {
    color: rgba($text-light, 0.5);
  }

  .course-list {
    &__title {
      width: 100%;
    }
    &__container {
      display: grid;
      grid-template-columns: 150px 150px;
      justify-content: space-around;
      background-color: rgba($kanazawaku, 0.3);
      padding: 52px 12px 44px 12px;
      position: relative;
      top: -37px;
      &__waypoint {
        margin-bottom: 24px;
        img {
          border-radius: 50%;
          object-fit: cover;
          height: 100px;
          width: 100px;
        }
        &__not-visited {
          border: 3px solid $sumi-500;
          filter: grayscale(100%);
        }
        &__visited {
          border: 3px solid $kanazawaku;
        }
        .visited-hanko {
          position: absolute;
          width: 50px;
          height: 50px;
          margin: -10px;
        }
      }
    }
    &__form {
      position: relative;
      top: -60px;
      width: 245px;
      padding: 12px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }
    .possible-submit {
      border: 1px solid $kanazawaku;
      background: $gururi-base-color;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
    }
    .impossible-submit {
      border: 1px solid $border-midium;
      background: $gururi-base-color;
    }
    .impossible-submit-text {
      color: $text-disabled;
    }
  }

  .how-visited {
    margin-top: -48px;
    img {
      margin: auto 0;
    }
    &__step {
      text-align: left;
      margin-top: 40px;
      margin-bottom: 40px;
      &__3 {
        color: $text-medium;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // center: [35.319065, 139.550412], //鎌倉
    center: [35.326242394397575, 139.55635630955146], // 鶴岡八幡宮
    historicSites: [],
    shops: [],
    visitedSpotIds: [],
    user: {
      sex: '',
      address: '',
      birthday: '',
    },
    isCurrentPosition: false,
  },
  mutations: {
    setCenter(state, latLng) {
      state.center = [latLng.lat, latLng.lng]
    },
    setHistoricSites(state, historicSites) {
      state.historicSites = historicSites
    },
    setShops(state, shops) {
      state.shops = shops
    },
    setUserInfo(store, user) {
      store.user = user
    },
    setVisitedSpotIds(state, visitedSpotIds) {
      state.visitedSpotIds = visitedSpotIds
    },
    setIsCurrentPosition(state, isCurrentPosition) {
      state.isCurrentPosition = isCurrentPosition
    },
  },
})

<template>
  <div class="reset-password-content" :style="styles">
    <BaseBackIcon />
    <form @submit.prevent="sendResetEmail">
      <h1 class="reset-password-title">パスワードの再設定</h1>
      <div class="reset-password-items">
        <div class="reset-password-item">
          <p class="reset-password-text">再設定用のメールアドレスを入力してください。</p>
          <label for="email">メールアドレス</label>
          <div>
            <img :src="MAIL_IMG" class="input-icon" />
            <input
              type="email"
              placeholder="メールアドレス"
              v-model="resetEmail"
              id="email"
              required
            />
          </div>
        </div>
      </div>
      <p style="color: red" class="mb-3">{{ errorMessage }}</p>
      <button type="submit" class="send-email">送信</button>
    </form>
  </div>
</template>
<script>
import firebase from 'firebase'
import { MAIL_IMG, PADLOCK_IMG } from '../constants/img'

export default {
  data() {
    return {
      MAIL_IMG: MAIL_IMG,
      PADLOCK_IMG: PADLOCK_IMG,
      errorMessage: '',
      toggleEyeIcon: false,
      resetEmail: '',
    }
  },
  methods: {
    sendResetEmail() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.resetEmail)
        .then(
          () => {
            alert('ご登録のメールアドレスに再設定のリンクを送付いたしました。')
            this.$router.push({ path: '/sign-in' })
          },
          (err) => {
            alert(
              'ご登録のメールアドレスがございません。再度メールアドレスのご確認をお願いします。'
            )
            console.log(err)
          }
        )
    },
  },
}
</script>
<style scoped lang="scss">
.reset-password-content {
  background-color: $bg-light-gray;
  width: 100%;
  padding: 0px 5%;
}
form {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
}
.reset-password-title {
  color: $gururi-brand-color;
  font-size: 28px;
  margin-bottom: 10%;
}
.reset-password-items {
  text-align: left;
  .reset-password-text {
    margin-bottom: 20px;
  }
  .reset-password-item {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border-light;

    label {
      margin-bottom: 15px;
    }
  }
}
input {
  border: none;
  outline: none;
  width: 80%;
  margin-left: 1%;
}
.input-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.send-email {
  background-color: $bg-light-gray;
  width: 40%;
  height: 40px;
  color: $text-dark;
  border: 1px solid;
}
</style>

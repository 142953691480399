var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{ref:"shopMarker",attrs:{"lat-lng":[_vm.shop.latLng.latitude, _vm.shop.latLng.longitude]},on:{"click":function($event){_vm.$router.push(
      {
        name: 'Shop',
        params: { id: _vm.shop.id },
      },
      () => {}
    )}}},[_c('l-icon',{attrs:{"icon-url":_vm.mapIconDisplay(_vm.shop.shopTypesId),"class-name":"shopIcon","icon-size":_vm.icon.iconSize}}),_c('l-popup',[_c('p',{ref:"shopId",attrs:{"hidden":""}},[_vm._v(_vm._s(_vm.shop.id))]),_c('div',{staticClass:"shop-popup"},[_c('img',{attrs:{"src":_vm.shop.photoURL,"oncontextmenu":"return false"}}),_c('div',{staticClass:"shop-popup-text"},[_c('h3',{staticStyle:{"margin-top":"0"}},[_vm._v(_vm._s(_vm.shop.title))]),_c('router-link',{attrs:{"to":{
            name: 'ShowShop',
            params: { id: _vm.shop.id },
          }}},[_c('div',[_c('span',{staticClass:"popup-button show-button"},[_vm._v(" 詳細を見る ")])])]),_c('div',[(_vm.storeVisitedSpotIds.includes(_vm.shop.id))?_c('div',[_c('span',{staticClass:"popup-button disabled"},[_vm._v(" 訪問スタンプを押す ")])]):_c('div',[(_vm.$auth.currentUser.uid)?_c('div',[_c('span',{staticClass:"popup-button",on:{"click":function($event){return _vm.$emit('compareCurrentAndVisited')}}},[_vm._v(" 訪問スタンプを押す ")])]):_c('div',[_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-beforeSignUp",modifiers:{"modal-beforeSignUp":true}}],staticClass:"popup-button"},[_vm._v(" 訪問スタンプを押す ")])])])]),(_vm.storeVisitedSpotIds.includes(_vm.shop.id))?_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{staticClass:"visited-hanko",attrs:{"src":_vm.VISITED_HANKO_IMG}})]):_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{staticClass:"visited-hanko",attrs:{"src":_vm.NOT_VISITED_HANKO_IMG}})])],1)])]),_c('l-tooltip',{attrs:{"options":{
      permanent: true,
      opacity: 0.8,
      offset: [10, 10],
      interactive: true,
    }}},[_c('div',[_vm._v(_vm._s(_vm.shop.title))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
export default {
  props: {
    buttonImg: {
      type: String,
      require: true,
    },
  },
}
</script>

<template>
  <div>
    <img :src="buttonImg" alt="cross-mark" @click="$router.back()" />
  </div>
</template>

<template>
  <b-modal id="modal-beforeSignUp" centered hide-footer hide-header>
    <div class="container__wrapper">
      <div style="text-align: right" @click="$bvModal.hide('modal-beforeSignUp')">
        <span class="material-icons">close</span>
      </div>
      <h1 class="title">ぐるりのメンバーになりませんか？</h1>
      <p style="margin-bottom: 10%">こんなことができるようになります！</p>
      <div class="box">
        <img :src="MAKIMONO_IMG" class="img" />
        <div>史跡の口コミを投稿</div>
      </div>
      <div class="box">
        <img :src="MAKIMONO_IMG" class="img" />
        <div>他の人の口コミにいいね</div>
      </div>
      <div class="box">
        <img :src="MAKIMONO_IMG" class="img" />
        <div>マイページ作成</div>
      </div>
      <div class="box">
        <img :src="MAKIMONO_IMG" class="img" />
        <div>クーポンの利用</div>
      </div>
      <div class="box">
        <img :src="MAKIMONO_IMG" class="img" />
        <div>訪問した史跡の記録</div>
      </div>
      <div class="button-box" @click="$bvModal.hide('modal-beforeSignUp')">
        <router-link to="/sign-up" style="color: white; width: 100%">
          <button class="member">メンバーになる</button>
        </router-link>
        <router-link to="/sign-in">
          <button class="login">メンバーの方はこちらからログイン</button>
        </router-link>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { MAKIMONO_IMG } from '../../constants/img'

export default {
  data() {
    return {
      MAKIMONO_IMG: MAKIMONO_IMG,
    }
  },
}
</script>

<style scoped lang="scss">
.container__wrapper {
  padding: 5%;
  font-family: 'Noto Sans JP', sans-serif;
}
.title {
  color: $gururi-brand-color;
  margin-bottom: 10%;
  margin-top: auto;
  font-size: 30px;
}

.img {
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
}
/* 巻物 と文章*/
.box {
  display: flex;
}
.button-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-box a {
  text-decoration: none;
}
/* メンバーになるボタン */
.member {
  margin-top: 15%;
  height: 50%;
  width: 100%;
  font-size: 25px;
  background-color: $gururi-brand-color;
  border: none;
  border-radius: 100px;
  color: $gururi-base-color;
  line-height: 50px;
}
/* ログインボタン */
.login {
  margin-top: 4%;
  height: 50%;
  width: 100%;
  font-size: 14px;
  background-color: $gururi-base-color;
  color: $text-dark;
  border: none;
  line-height: 35px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Map from '@/views/Map.vue'
import ShowHistoricSite from '@/views/ShowHistoricSite.vue'
import User from '@/views/User.vue'
import Home from '@/views/Home.vue'
import Category from '@/views/Category'
import SubCategory from '@/views/SubCategory'
import SignIn from '@/views/SignIn'
import SignUp from '@/views/SignUp'
import ShowShop from '@/views/ShowShop'
import ShopList from '@/views/ShopList'
import ShowCoupon from '@/views/ShowCoupon'
import UserTerm from '@/views/UserTerm'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import ResetPassword from '@/views/ResetPassword'
import ShowMoment from '@/views/ShowMoment'
import PostMoment from '@/views/PostMoment'
import MomentList from '@/views/MomentList'
import EventPage from '@/views/EventPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Map',
    component: Map,
  },
  {
    path: '/historic-site/:id',
    name: 'HistoricSite',
    component: Map,
  },
  {
    path: '/show-historic-site/:id',
    name: 'ShowHistoricSite',
    component: ShowHistoricSite,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: Category,
  },
  {
    path: '/category/:categoryId/subCategory/:subCategoryId',
    name: 'SubCategory',
    component: SubCategory,
  },
  {
    path: '/shop-list/:id',
    name: 'ShopList',
    component: ShopList,
  },
  {
    path: '/show-shop/:id',
    name: 'ShowShop',
    component: ShowShop,
  },
  {
    path: '/show-coupon/:id',
    name: 'ShowCoupon',
    component: ShowCoupon,
  },
  {
    path: '/shop/:id',
    name: 'Shop',
    component: Map,
  },
  {
    path: '/user-term',
    name: 'UserTerm',
    component: UserTerm,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/show-moment/:id',
    name: 'ShowMoment',
    component: ShowMoment,
  },
  {
    path: '/post-moment/',
    name: 'PostMoment',
    component: PostMoment,
  },
  {
    path: '/moment-list/',
    name: 'MomentList',
    component: MomentList,
  },
  {
    path: '/event-page/',
    name: 'EventPage',
    component: EventPage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

<template>
  <div v-if="isShowHeader" class="header-wrapper">
    <div class="header-items">
      <div>
        <div v-if="$auth.currentUser.uid">
          <router-link
            :to="{
              name: 'User',
              params: { id: $auth.currentUser.uid },
            }"
          >
            <img class="profile-icon" :src="$auth.currentUser.photoURL" />
          </router-link>
        </div>
        <div v-else>
          <img v-b-modal.modal-beforeSignUp class="profile-icon" :src="USER_ICON_IMG" />
          <BeforeSignUp />
        </div>
      </div>

      <div @click="$router.push({ path: '/home' })">
        <img :src="NEW_GURURI_LOGO_IMG" height="21px" width="120px" />
      </div>

      <span class="material-icons" @click="isMenu = true">
        <img :src="HAMBURGER_MENU_IMG" />
      </span>
    </div>
    <!-- 以下ハンバーガーメニュー -->
    <div
      :class="isMenu ? 'header-background fade-animation' : 'header-background'"
      :style="isMenu ? 'top:0%' : 'display: none'"
      @click="isMenu = false"
    >
      <div class="menu_container">
        <div class="close-icon-container">
          <div class="close-icon">
            <b-icon-x class="b-icon-x" />
          </div>
        </div>
        <div class="after-login">
          <div v-if="$auth.currentUser.uid" class="login-container">
            <div>
              <div class="login-items">
                <img class="login-icon" :src="$auth.currentUser.photoURL" />
                <div class="login-text">
                  <p class="user-name">{{ $auth.currentUser.displayName }}</p>
                  <router-link
                    :to="{
                      name: 'User',
                      params: { id: $auth.currentUser.uid },
                    }"
                  >
                    <p class="my-page">マイページを表示 ></p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="menu_top">
            <div class="menu-button-container">
              <div class="menu-button">
                <div class="nav-top-btn register" @click="$router.push({ path: '/sign-up' })">
                  <p class="register-btn-text">新規会員登録</p>
                </div>

                <div class="nav-top-btn login" @click="$router.push({ path: '/sign-in' })">
                  <p class="login-btn-text">ログイン</p>
                </div>
              </div>
            </div>
            <div class="description-box">
              <p class="CF_body-sub">
                ログインすると、史跡の口コミ投稿や、マイページから訪問履歴を閲覧することができます。
              </p>
            </div>
          </div>
        </div>
        <div class="menu_medium">
          <div @click="$router.push({ path: '/home' })" class="menu">
            <div class="icons">
              <img :src="HOME_ICON_IMG" height="20px" width="20px" />
              <p class="CF_button-medium-text">トップページ</p>
            </div>
          </div>

          <div @click="$router.push({ path: '/' })" class="menu">
            <div class="icons">
              <img :src="MAP_ICON_IMG" height="20px" width="20px" />
              <p class="CF_button-medium-text">地図</p>
            </div>
          </div>

          <div @click="$router.push({ path: '/moment-list' })" class="menu">
            <div class="icons">
              <img :src="SHOW_MOMENT_IMG" height="20px" width="20px" />
              <p class="CF_button-medium-text">みんなの探訪記</p>
            </div>
          </div>
        </div>

        <!-- <div class="menu-event">
          <div class="CF_button-medium-text">イベント情報</div>
          <img
            :src="OPEN_KANAZAWA_EVENT_IMG"
            @click="$router.push({ path: '/event-page' })"
            class="kanazawa-event-img"
            alt="金沢区イベントページ"
            width="246px"
          />
        </div> -->

        <div class="menu_bottom">
          <div class="menu">
            <div class="icons">
              <a href="https://gururi-guide.notion.site/acf503d9033d4c65adf13086f8a812b8?pvs=4">
                <img :src="HELP_ICON_IMG" height="20px" width="20px" />
              </a>
              <a
                href="https://gururi-guide.notion.site/acf503d9033d4c65adf13086f8a812b8?pvs=4"
                class="CF_button-medium-text"
                >ヘルプセンター</a
              >
            </div>
            <div>
              <a href="https://gururi-guide.notion.site/acf503d9033d4c65adf13086f8a812b8?pvs=4">
                <img :src="EXTERNAL_LINK_ICON_IMG" height="24px" width="24px" />
              </a>
            </div>
          </div>
          <p class="menu icons">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeo1BWpj8ftl0t0omuv4SnAOYnAQysX2zbfX7vp4A4SZkujKA/viewform"
              ><img :src="HELP_ICON_IMG" height="20px" width="20px"
            /></a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeo1BWpj8ftl0t0omuv4SnAOYnAQysX2zbfX7vp4A4SZkujKA/viewform"
              class="CF_button-medium-text"
              >お問い合わせ</a
            >
          </p>

          <div @click="$router.push({ path: '/privacy-policy' })" class="menu">
            <div class="icons">
              <img :src="PRIVACY_POLICY_ICON_IMG" height="20px" width="20px" />
              <p class="privacy-policy CF_button-medium-text">プライバシーポリシー</p>
            </div>
          </div>

          <div @click="$router.push({ path: '/user-term' })" class="menu">
            <div class="icons">
              <img :src="DOCS_ICON_IMG" height="20px" width="20px" />
              <p class="CF_button-medium-text">ユーザー利用規約</p>
            </div>
          </div>

          <div v-if="$auth.currentUser.uid">
            <div @click="signOut">
              <div class="icons">
                <img :src="LOGOUT_IMG" height="20px" width="20px" />
                <p>ログアウト</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ハンバーガーメニューここまで -->
  </div>
</template>

<script>
import firebase from 'firebase'
import {
  NEW_GURURI_LOGO_IMG,
  USER_ICON_IMG,
  HOME_ICON_IMG,
  DOCS_ICON_IMG,
  HELP_ICON_IMG,
  PRIVACY_POLICY_ICON_IMG,
  MAP_ICON_IMG,
  HAMBURGER_MENU_IMG,
  LOGOUT_IMG,
  SHOW_MOMENT_IMG,
  EXTERNAL_LINK_ICON_IMG,
} from '../../constants/img'
import BeforeSignUp from '../modals/BeforeSignUpModal.vue'

export default {
  components: {
    BeforeSignUp,
  },
  data() {
    return {
      user: [],
      NEW_GURURI_LOGO_IMG: NEW_GURURI_LOGO_IMG,
      USER_ICON_IMG: USER_ICON_IMG,
      HOME_ICON_IMG: HOME_ICON_IMG,
      DOCS_ICON_IMG: DOCS_ICON_IMG,
      HELP_ICON_IMG: HELP_ICON_IMG,
      PRIVACY_POLICY_ICON_IMG: PRIVACY_POLICY_ICON_IMG,
      MAP_ICON_IMG: MAP_ICON_IMG,
      HAMBURGER_MENU_IMG: HAMBURGER_MENU_IMG,
      LOGOUT_IMG: LOGOUT_IMG,
      SHOW_MOMENT_IMG: SHOW_MOMENT_IMG,
      EXTERNAL_LINK_ICON_IMG: EXTERNAL_LINK_ICON_IMG,
      isMenu: false,
      isShowHeader: true,
      deleteHeaderPath: ['post-moment', 'user'],
    }
  },
  watch: {
    $route(to) {
      this.checkDeleteHeaderPath(to.path)
    },
  },
  // ページ遷移によって変更する
  methods: {
    signOut() {
      firebase.auth().signOut()
      this.$auth.currentUser.isEmailVerified = false
      this.$swal({
        title: 'ログアウト完了',
        icon: 'success',
        focusConfirm: false,
        confirmButtonColor: '#ec856e',
      })
      this.$router.push('/')
    },
    checkDeleteHeaderPath(path) {
      const LAST_PATH = 1
      const currentPath = path.split('/')[LAST_PATH]
      if (this.deleteHeaderPath.includes(currentPath)) {
        this.isShowHeader = false
      } else {
        this.isShowHeader = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: $gururi-base-color;
}

p,
h1,
h2 {
  margin: 0;
}

/* ヘッダー
---------------------------- */
$z-index: 1007;

.header-wrapper {
  background: $gururi-base-color;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: $z-index;

  .header-items {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 16px;
  }
}

.material-icons {
  color: $sumi-alpha-100;
  text-align: center;
  font-size: 0px;

  &:hover {
    color: $gururi-brand-color;
  }
}
.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
}
// 以下ハンバーガーメニュー部分

.header-background {
  width: 100%;
  position: fixed;
  z-index: 1008;
  background-color: $text-medium;
  height: 100vh;
}
.fade-animation {
  animation-name: fade;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.menu_container {
  margin: 0px 0px 10px auto;
  width: 74%;
  height: 100%;
  background-color: $gururi-base-color;

  .close-icon-container {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid $border-light;

    .close-icon {
      display: flex;
      padding: 10px 0px;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      .b-icon-x {
        display: flex;
        width: 26px;
        height: 26px;
        justify-content: center;
        align-items: center;
        color: $text-medium;
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 0px;
    justify-content: flex-start;
  }

  p {
    color: $text-dark;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.04em;
  }
  .register {
    // content-style
    border-radius: 1000px;
    background: $gururi-brand-color;
    white-space: nowrap;
  }
  .register-btn-text {
    // font-style
    color: $gururi-base-color;
    font-feature-settings: 'pwid' on;
    /* Button/medium text */
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
  }
  .login {
    // content-style
    border-radius: 1000px;
    border: 1px solid $gururi-brand-color;
    background: $gururi-base-color;
    white-space: nowrap;
  }
  .login-btn-text {
    // font-style
    color: $gururi-brand-color;
    font-feature-settings: 'pwid' on;
    /* Button/medium text */
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
  }
  .menu {
    text-align: center;
    width: 70%;
    display: flex;

    a {
      color: $text-dark;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.04em;
    }
  }

  .menu_top {
    margin: 20px 10px;
    .menu-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
      @media (max-width: 350px) {
        flex-direction: column;
      }
      .nav-top-btn {
        margin: 0px;
        display: flex;
        width: 100%;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
    .description-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      p {
        color: $text-dark;
        font-weight: 500;
        line-height: 175%;
        letter-spacing: 0.04em;
      }
    }
  }
  .menu_medium {
    border-bottom: 1px solid $border-light;
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .menu-event {
    border-bottom: 1px solid $border-light;
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .kanazawa-event-img {
    width: 246px;
    border-radius: 4px;
    border: 1px solid $border-light;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  }
  .menu_bottom {
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .privacy-policy {
      @media (min-width: 270px) {
        white-space: nowrap;
      }
    }
  }
  .after-login {
    border-bottom: 1px solid $border-light;

    .login-container {
      display: flex;
      padding: 10px 16px 16px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
    }
    .login-items {
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .login-icon {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
    }

    .login-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      .user-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
      }

      .my-page {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: $text-medium;
      }
    }
  }
}

// ハンバーガーメニューここまで

a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

p,
h1,
h2 {
  margin: 0;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 2px;
  font-size: small;
  width: 45%;
  height: 10%;
  display: flex;
  margin: 8% auto 0;
  line-height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 350px) {
    width: 120px;
    height: 41px;
  }

  @media (max-width: 150px) {
    height: 50%;
    width: 100%;
  }
}
</style>

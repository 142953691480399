var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.historicSites),function(historicSite){return _c('div',{key:historicSite.id},[_c('l-marker',{ref:"historicSiteMarker",refInFor:true,attrs:{"lat-lng":[historicSite.latLng.latitude, historicSite.latLng.longitude]},on:{"click":function($event){return _vm.handleMarkerClick(historicSite.id)}}},[_c('l-icon',{attrs:{"icon-url":historicSite.mapIconURL ? historicSite.mapIconURL : '/images/historicSiteIcon.png',"class-name":"shopIcon","icon-size":_vm.icon.iconSize}}),_c('l-popup',[_c('p',{ref:"historicSiteId",refInFor:true,attrs:{"hidden":""}},[_vm._v(_vm._s(historicSite.id))]),_c('div',{staticClass:"historic-site-popup"},[_c('img',{attrs:{"src":historicSite.photoURL,"oncontextmenu":"return false"}}),_c('div',{staticClass:"historic-site-popup-text"},[_c('div',[_vm._v(_vm._s(historicSite.categoryNames[0]?.replaceAll('\\n', '\n')))]),_c('h3',{staticStyle:{"margin-top":"0"}},[_vm._v(_vm._s(historicSite.title))]),_c('router-link',{attrs:{"to":{
                name: 'ShowHistoricSite',
                params: { id: historicSite.id },
              }}},[_c('div',[_c('span',{staticClass:"popup-button show-button"},[_vm._v(" 詳細を見る ")])])]),_c('div',[(_vm.storeVisitedSpotIds.includes(historicSite.id))?_c('div',[_c('span',{staticClass:"popup-button disabled"},[_vm._v(" 訪問スタンプを押す ")])]):_c('div',[(_vm.$auth.currentUser.uid)?_c('div',[_c('span',{staticClass:"popup-button",on:{"click":function($event){return _vm.$emit('compareCurrentAndVisited', historicSite.id)}}},[_vm._v(" 訪問スタンプを押す ")])]):_c('div',[_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-beforeSignUp",modifiers:{"modal-beforeSignUp":true}}],staticClass:"popup-button"},[_vm._v(" 訪問スタンプを押す ")])])])]),(_vm.storeVisitedSpotIds.includes(historicSite.id))?_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{staticClass:"visited-hanko",attrs:{"src":_vm.VISITED_HANKO_IMG}})]):_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{staticClass:"visited-hanko",attrs:{"src":_vm.NOT_VISITED_HANKO_IMG}})])],1)])]),_c('l-tooltip',{attrs:{"options":{
          permanent: true,
          opacity: 0.8,
          offset: [10, 10],
          interactive: true,
        }}},[_c('div',[_vm._v(_vm._s(historicSite.title))])])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
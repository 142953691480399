<script>
import LinkButton from '../button/LinkButton.vue'
export default {
  components: {
    LinkButton,
  },
  props: {
    spotData: {
      type: Array,
      require: true,
    },
    dataType: {
      type: String,
      require: true,
    },
  },
}
</script>

<template>
  <div>
    <div
      v-for="spotInfo in spotData"
      :key="spotInfo.id"
      class="card-item"
      oncontextmenu="return false"
    >
      <img :src="spotInfo.photoURL" alt="史跡の画像" class="card-img" />
      <div class="is-coupon" v-if="spotInfo.hasCoupon">クーポンあり</div>
      <div class="card-description">
        <div class="CF_heading-2">{{ spotInfo.title }}</div>
        <div class="card-button" v-if="dataType === 'historic'">
          <LinkButton
            class="bg-primary mb-3"
            buttonText="詳細を見る"
            toName="ShowHistoricSite"
            :params="spotInfo.id"
          />
          <LinkButton
            class="bg-warning"
            buttonText="マップを見る"
            toName="HistoricSite"
            :params="spotInfo.id"
          />
        </div>
        <div class="card-button" v-if="dataType === 'shop'">
          <LinkButton
            class="bg-primary mb-3"
            buttonText="詳細を見る"
            :toName="
              spotInfo.shopTypesId === 'I0YRd3LYijRBiMWvlHFZ' ? 'ShowHistoricSite' : 'ShowShop'
            "
            :params="spotInfo.id"
          />
          <LinkButton
            class="bg-warning"
            buttonText="マップを見る"
            :toName="spotInfo.shopTypesId === 'I0YRd3LYijRBiMWvlHFZ' ? 'HistoricSite' : 'Shop'"
            :params="spotInfo.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-item {
  text-align: left;
  margin-bottom: 40px;
  margin: 0 16px 40px;
  position: relative;
}
.card-img {
  width: 100%;
  object-fit: cover;
  height: 168px;
  border-radius: 10px 10px 0 0;
}
.card-description {
  padding: 16px;
  border-radius: 0 0 10px 10px;
  background: $bg-light-gray;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15); // デザイントークンに存在しないため直書き
}
.card-button {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.is-coupon {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 14px;
  background: #e9546b;
  text-align: center;
  border-radius: 0px 0px 8px 8px;
  padding: 6px 12px;
  color: white;
  box-shadow: 4px 4px 10px #333333c2;
}
</style>

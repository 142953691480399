<template>
  <div>
    <div v-for="historicSite in historicSites" :key="historicSite.id">
      <l-marker
        :lat-lng="[historicSite.latLng.latitude, historicSite.latLng.longitude]"
        @click="handleMarkerClick(historicSite.id)"
        ref="historicSiteMarker"
      >
        <l-icon
          :icon-url="
            historicSite.mapIconURL ? historicSite.mapIconURL : '/images/historicSiteIcon.png'
          "
          class-name="shopIcon"
          :icon-size="icon.iconSize"
        />
        <l-popup>
          <p hidden ref="historicSiteId">{{ historicSite.id }}</p>
          <div class="historic-site-popup">
            <img :src="historicSite.photoURL" oncontextmenu="return false" />
            <div class="historic-site-popup-text">
              <div>{{ historicSite.categoryNames[0]?.replaceAll('\\n', '\n') }}</div>
              <h3 style="margin-top: 0">{{ historicSite.title }}</h3>
              <router-link
                :to="{
                  name: 'ShowHistoricSite',
                  params: { id: historicSite.id },
                }"
              >
                <div>
                  <span class="popup-button show-button"> 詳細を見る </span>
                </div>
              </router-link>
              <div>
                <div v-if="storeVisitedSpotIds.includes(historicSite.id)">
                  <!-- 訪問済み -->
                  <span class="popup-button disabled"> 訪問スタンプを押す </span>
                </div>
                <div v-else>
                  <div v-if="$auth.currentUser.uid">
                    <!-- ログイン済み 未訪問 -->
                    <span
                      class="popup-button"
                      @click="$emit('compareCurrentAndVisited', historicSite.id)"
                    >
                      訪問スタンプを押す
                    </span>
                  </div>
                  <div v-else>
                    <!-- 未ログイン -->
                    <span class="popup-button" v-b-modal.modal-beforeSignUp>
                      訪問スタンプを押す
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="storeVisitedSpotIds.includes(historicSite.id)" style="text-align: right">
                <img :src="VISITED_HANKO_IMG" class="visited-hanko" />
              </div>
              <div v-else style="text-align: right">
                <img :src="NOT_VISITED_HANKO_IMG" class="visited-hanko" />
              </div>
            </div>
          </div>
        </l-popup>
        <l-tooltip
          :options="{
            permanent: true,
            opacity: 0.8,
            offset: [10, 10],
            interactive: true,
          }"
        >
          <div>{{ historicSite.title }}</div>
        </l-tooltip>
      </l-marker>
    </div>
  </div>
</template>

<script>
import { LMarker, LPopup, LTooltip, LIcon } from 'vue2-leaflet'
import { VISITED_HANKO_IMG, NOT_VISITED_HANKO_IMG } from '../../constants/img'

export default {
  components: {
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
  },
  props: {
    historicSites: {
      type: Array,
      require: true,
    },
    icon: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      VISITED_HANKO_IMG: VISITED_HANKO_IMG,
      NOT_VISITED_HANKO_IMG: NOT_VISITED_HANKO_IMG,
    }
  },
  created() {
    if (this.$route.name == 'HistoricSite') {
      this.openHistoricSitePopup(this.$route.params.id)
    }
  },
  methods: {
    handleMarkerClick(id) {
      this.$router
        .push({
          name: 'HistoricSite',
          params: { id: id },
        })
        .catch((err) => {
          if (err.name != 'NavigationDuplicated') {
            throw err
          }
        })
      this.$emit('marker-click')
    },
    // 選択している史跡のポップアップを開く状態にする
    openHistoricSitePopup(currentHistoricSiteId) {
      this.$nextTick(() => {
        if (!this.$refs.historicSiteId) return
        const indexForPopup = this.$refs.historicSiteId.findIndex(
          (item) => currentHistoricSiteId == item.innerHTML
        )
        this.$refs.historicSiteMarker[indexForPopup].mapObject.openPopup()
      })
    },
  },
  computed: {
    // 訪問済みスポットのIDを保持
    storeVisitedSpotIds() {
      return this.$store.state.visitedSpotIds.map((doc) => doc.visitedSpotId)
    },
  },
}
</script>

<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
}

h3 {
  font-size: 16px;
  font-family: 'Noto Sans JP', sans-serif;
}

.historic-site-popup {
  display: flex;
  width: 266px;

  img {
    width: 70px;
    border-radius: 10px;
    object-fit: cover;
  }
  .historic-site-popup-text {
    margin-left: 15px;
  }
  .visited-hanko {
    position: absolute;
    right: -7%;
    bottom: -10%;
    width: 67px;
  }
}
.popup-button {
  display: inline-block;
  border-radius: 3px;
  margin: 0 auto;
  max-width: 220px;
  padding: 8px 16px;
  color: $gururi-base-color;
  font-weight: 500;
  background: $gururi-brand-color;
  cursor: pointer;
}
.disabled {
  background: $bg-midium-gray;
  cursor: text;
}
.show-button {
  background: $asagi-600;
  margin-bottom: 8px;
}
</style>

<template>
  <div class="mt-5 pt-5 mb-3 pb-3">
    <BaseBackIcon class="ms-3" />
    <Header />
    <div class="mt-3 mb-3">
      <img
        :src="couponData.displayImage ? couponData.displayImage : shopDate.photoURL"
        width="100%"
        height="236"
        oncontextmenu="return false"
      />
    </div>
    <div class="coupon-detail">
      <div class="coupon-detail__info">
        <p class="coupon-detail__info__shop-name">{{ shopDate.title }}</p>
        <h1 class="coupon-detail__info__shop-coupon">{{ couponData.name }}</h1>
        <p class="coupon-detail__info__describe" v-if="couponData.note">
          {{ couponData.note }}
        </p>
      </div>
      <div class="coupon-detail__attention">
        <p class="coupon-detail__attention__price" v-if="couponData.condition">
          ※{{ couponData.condition }}
        </p>
        <p class="coupon-detail__attention__time" v-if="couponData.expirationDate">
          ※ {{ formattedExpirationDate }}
        </p>
      </div>
    </div>

    <button v-if="coupon" class="coupon-use-btn" @click="openModal">クーポンを使用する</button>
    <button v-else class="coupon-used">使用済み</button>
    <br />
    <router-link
      v-if="shopDate.id"
      :to="{
        name: 'ShowShop',
        params: { id: shopDate.id },
      }"
    >
      <button class="shoplist-back-btn">店舗情報へ戻る</button>
    </router-link>

    <Modal
      v-show="modal"
      @closeModal="closeModal"
      @closeCoupon="closeCoupon"
      :image="couponData.image"
      :couponId="couponData.id"
      :shopId="shopDate.id"
      :shopTitle="shopDate.title"
    />
  </div>
</template>

<script>
import Header from '../components/layouts/Header.vue'
import Modal from '../components/modals/CouponModal.vue'
import firebase from 'firebase'
export default {
  components: {
    Modal,
    Header,
  },
  data() {
    return {
      modal: false,
      coupon: true,
      couponData: {},
      shopDate: {},
    }
  },
  computed: {
    formattedExpirationDate() {
      if (this.couponData.expirationDate) {
        const expirationDate = this.couponData.expirationDate.toDate()
        const year = expirationDate.getFullYear()
        const month = expirationDate.getMonth() + 1
        const day = expirationDate.getDate()

        return `${year}年${month}月${day}日まで有効`
      }
      return ''
    },
  },
  created() {
    const firestore = firebase.firestore()
    // クーポン取得
    firestore
      .collection('coupons')
      .doc(this.$route.params.id)
      .get()
      .then((snapshot) => {
        this.couponData = {
          id: snapshot.id,
          ...snapshot.data(),
        }
        // 店舗情報取得
        firestore
          .collection('shops')
          .doc(snapshot.data().shopId)
          .get()
          .then((snapshot) => {
            this.shopDate = {
              id: snapshot.id,
              ...snapshot.data(),
            }
          })
      })
    // クーポンが使用済みかどうか
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection('couponHistory')
          .where('couponId', '==', this.$route.params.id)
          .where('userId', '==', user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length) {
              this.coupon = false
            }
          })
      }
    })
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    closeCoupon() {
      this.coupon = false
      this.modal = false
    },
  },
}
</script>

<style lang="scss" scoped>
/* 共通パーツ
---------------------------- */
button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 100px;
  width: 95%;
  height: 44px;
  font-weight: 700;
  font-size: 18px;
}
// クーポンの詳細
.coupon-detail {
  text-align: left;
  font-weight: 700;
  margin-left: 16px;
  &__info {
    &__shop-name {
      font-size: 18px;
      color: $text-dark;
    }
    &__shop-coupon {
      font-size: 36px;
      color: $asagi-600;
      margin-top: 10px;
      font-weight: 700;
    }
    &__describe {
      font-size: 14px;
      color: $text-dark;
    }
  }
  &__attention {
    font-size: 14px;
    color: $text-disabled;
    margin-top: 12px;
  }
}
.coupon-use-btn {
  background: $gururi-brand-color;
  color: $text-white;
  margin-top: 34px;
}
.coupon-used {
  background: $bg-light-gray;
  color: $text-white;
  margin-top: 34px;
}
.shoplist-back-btn {
  margin-top: 12px;
  border: 1px solid $gururi-brand-color;
  color: $gururi-brand-color;
}
</style>

<template>
  <b-container class="sign-up-content">
    <BaseBackIcon />
    <form @submit.prevent="signUp">
      <h1 class="sign-up-title">アカウント作成</h1>
      <div class="sign-up-items">
        <div class="sign-up-item">
          <label for="email">登録用メールアドレス</label>
          <div>
            <img :src="MAIL_IMG" class="input-icon" />
            <input
              type="email"
              placeholder="メールアドレス"
              v-model="signUpEmail"
              id="email"
              required
            />
          </div>
        </div>

        <div class="sign-up-item">
          <label for="password">
            <p class="m-0">パスワード</p>
            <span class="pass-sub-desc"> ※ 8文字以上の半角英数字を組み合わせる </span>
          </label>
          <div>
            <img :src="PADLOCK_IMG" class="input-icon" />
            <input
              :type="toggleEyeIcon ? 'text' : 'password'"
              id="password"
              v-model="signUpPassword"
              placeholder="パスワード"
              autocomplete="on"
              pattern="^[0-9A-Za-z]+$"
              minlength="8"
              required
            />
            <span @click="toggleEyeIcon = !toggleEyeIcon">
              <b-icon-eye v-if="toggleEyeIcon" />
              <b-icon-eye-slash v-if="!toggleEyeIcon" />
            </span>
          </div>
        </div>

        <div class="sign-up-item">
          <b-form-group label="性別" v-slot="{ ariaDescribedby }">
            <div class="sex">
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="sex-radios"
                v-model="user.sex"
                value="男"
                required
              >
                男性
              </b-form-radio>
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="sex-radios"
                v-model="user.sex"
                value="女"
                class="ms-4"
                required
              >
                女性
              </b-form-radio>
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="sex-radios"
                v-model="user.sex"
                value="その他"
                class="ms-4"
                required
              >
                その他
              </b-form-radio>
            </div>
          </b-form-group>
        </div>

        <div class="sign-up-item">
          <div>生年月日</div>
          <div class="birthday">
            <div>
              <select v-model="birthday.year" required>
                <option></option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
              <span>年</span>
            </div>
            <div class="ms-4">
              <select v-model="birthday.month" required>
                <option></option>
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
              <span>月</span>
            </div>
            <div class="ms-4">
              <select v-model="birthday.day" required>
                <option></option>
                <option v-for="day in days" :key="day" :value="day">
                  {{ day }}
                </option>
              </select>
              <span>日</span>
            </div>
          </div>
        </div>

        <div class="sign-up-item">
          <div>居住地</div>
          <select v-model="user.address" required>
            <option></option>
            <option v-for="(prefecture, index) in prefectures" :key="index" :value="prefecture">
              {{ prefecture }}
            </option>
          </select>
        </div>
      </div>
      <div style="font-size: 10px" class="pb-3">
        <span v-b-modal.user-term style="color: #23abdd"> 利用規約 </span>
        および
        <span v-b-modal.privacy-policy style="color: #23abdd"> プライバシーポリシー </span>
        に同意して新規登録する
      </div>
      <UserTermModal />
      <PrivacyPolicyModal />
      <p style="color: red" class="mb-2">{{ errorMessage }}</p>
      <button type="submit" class="login">新規登録</button>
    </form>
  </b-container>
</template>
<script>
import firebase from 'firebase'
import PrivacyPolicyModal from '../components/modals/PrivacyPolicyModal.vue'
import UserTermModal from '../components/modals/UserTermModal.vue'
import { MAIL_IMG, PADLOCK_IMG } from '../constants/img'
import { PREFECTURE, YEARS, MONTHS, DAY } from '../constants/code'

export default {
  components: {
    UserTermModal,
    PrivacyPolicyModal,
  },
  data() {
    return {
      MAIL_IMG: MAIL_IMG,
      PADLOCK_IMG: PADLOCK_IMG,
      signUpEmail: '',
      signUpPassword: '',
      birthday: {
        year: '',
        month: '',
        day: '',
      },
      user: {
        sex: '',
        address: '',
        birthday: '',
      },
      errorMessage: '',
      toggleEyeIcon: false,
      prefectures: PREFECTURE,
      years: YEARS,
      months: MONTHS,
      days: DAY,
    }
  },
  methods: {
    signUp() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.signUpEmail, this.signUpPassword)
        .then((userCredential) => {
          this.user.birthday = this.formatBirthday
          this.$store.commit('setUserInfo', {
            ...this.user,
          })
          const { user } = userCredential
          let actionCodeSettings = {}

          switch (location.hostname) {
            case process.env.VUE_APP_HOSTNAME_LOCAL:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_LOCAL_URL,
              }
              break
            case process.env.VUE_APP_HOSTNAME_MAIN:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_MAIN_URL,
              }
              break
            case process.env.VUE_APP_HOSTNAME_STAGING:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_STAGING_URL,
              }
              break
          }

          user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              alert(
                '本人確認のため、メールアドレスの認証をお願いいたします。入力されたメールアドレス宛に、認証用のメールをお送りしています。※迷惑メールに振り分けられていることがあるのでご注意ください。'
              )
            })
            .catch((error) => {
              alert(
                '確認メールを送信できませんでした。しばらく時間をおいてから再度お試しください。'
              )
              console.log(error)
            })
        })
        .catch((e) => {
          switch (e.code) {
            case 'auth/invalid-email':
              this.errorMessage = '無効のメールアドレスです'
              break
            case 'auth/weak-password':
              this.errorMessage = '8文字以上でパスワードを設定してください'
              break
            case 'auth/email-already-in-use':
              this.errorMessage = 'すでに存在しているメールアドレスです'
              break
            default:
              this.errorMessage = e.message
              break
          }
        })
    },
  },
  computed: {
    formatBirthday() {
      return this.birthday.year + '/' + this.birthday.month + '/' + this.birthday.day
    },
  },
}
</script>
<style scoped lang="scss">
.sign-up-content {
  width: 100%;
  padding: 0px 5%;
  margin-top: 5rem;
}
.sign-up-title {
  color: $gururi-brand-color;
  font-size: 28px;
  margin-bottom: 10%;
}
.sign-up-items {
  text-align: left;
  .sign-up-item {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border-light;

    label {
      margin-bottom: 15px;
    }
  }
}
input {
  border: none;
  outline: none;
  width: 80%;
  margin-left: 1%;
}
.input-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.login {
  background-color: $gururi-brand-color;
  color: $text-white;
  width: 40%;
  height: 40px;
  border-width: 0px;
  margin-bottom: 15%;
}
.pass-sub-desc {
  font-size: 0.8rem;
}
.sex {
  display: flex;
}
.birthday {
  display: flex;
}
</style>

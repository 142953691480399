export const categoryDesc = {
  kanazanwa: {
    id: 'LoYTE90zHcWv586QHQAT',
    about:
      '横浜市金沢区内には、歴史・自然・ショッピングなど、一日中楽しめるような魅力が満載です。\n\
そんな金沢区を、自転車に乗ってサイクリングしてみませんか。\n\
「GURURI」を使えば、各テーマに沿ったコースを地図上で確認しながら、巡ることができます。\n\
そのスポットにまつわる歴史情報やおすすめポイントを音声ガイドで聞くことも！\n\
ぜひ、「GURURI」で金沢の魅力を味わってみてください。',
    description:
      '・自転車を利用するときは、<span style="color: #D9333F; text-decoration:underline;">「自転車安全利用五則」などの交通ルールを遵守するとともに交通マナーを実践するなど</span>安全運転を心掛けましょう。\n\
・各スポット併設の駐輪場や指定の駐輪スペース、またはお近くの駐輪場を利用してください。遊歩道と公園は原則自転車走行禁止です。\
また、<span style="color: #D9333F; text-decoration:underline;">その他のスポットでも自転車の乗り入れが禁止されている場所があります</span>ので、ルールやマナーを守ってお楽しみください。\n\n\
【横浜市広域シェアサイクル事業社会実験実施中】\n\
サイクルステーションの位置・利用方法などは<a href="https://www.hellocycling.jp/station/kanagawa/%E6%A8%AA%E6%B5%9C%E5%B8%82">こちら</a>から',
  },
}

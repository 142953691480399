var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-3 mt-5 pt-3 mb-3 pb-3"},[_c('BaseBackIcon'),(_vm.isHasOtherSpot)?_c('div',[_c('Tab',{attrs:{"currentRouterId":_vm.$route.params.id,"shopData":_vm.shops,"historicData":_vm.historicData}})],1):_c('div',[(_vm.$route.params.id === _vm.categoryDesc.kanazanwa.id)?_c('div',[_c('img',{attrs:{"src":this.categoryImg,"alt":"カテゴリサムネイル画像","width":"100%"}}),_c('div',{staticClass:"CF_body-sub about-event-title"},[_vm._v("コースについて")]),_c('p',{staticClass:"CF_body-sub about-text",domProps:{"innerHTML":_vm._s(_vm.categoryDesc.kanazanwa.about)}})]):_vm._e(),_vm._l((_vm.subCategories),function(subCategory){return _c('router-link',{key:subCategory.id,staticClass:"position-relative",attrs:{"to":{
        name: 'SubCategory',
        params: {
          categoryId: _vm.$route.params.id,
          subCategoryId: subCategory.id,
        },
      }}},[_c('img',{staticClass:"sub-category-img w-100 rounded-3 mb-3",attrs:{"src":subCategory.photoURL,"oncontextmenu":"return false"}}),_c('div',{staticClass:"text-white h4 position-absolute top-50 start-50 translate-middle"},[_vm._v(" "+_vm._s(subCategory.name)+" ")])])}),(_vm.$route.params.id === _vm.categoryDesc.kanazanwa.id)?_c('div',[_c('p',{staticClass:"CF_body-main description",domProps:{"innerHTML":_vm._s(_vm.categoryDesc.kanazanwa.description)}})]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
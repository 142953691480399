import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './firebase'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './registerServiceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(VueClipboard)

// vue2-leafletの設定
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
//ここまでvue2-leaflet

Vue.config.productionTip = false

// 位置情報追跡(現在地マーカー用)
const $position = Vue.observable({
  latitude: 35.319065,
  longitude: 139.550412,
})
navigator.geolocation.watchPosition((position) => {
  Object.assign($position, {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  })
})
Vue.prototype.$position = $position

// 基底コンポーネントをグローバルにインポート
const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

<template>
  <div class="mt-5 pt-3 mb-3 pb-3">
    <BaseBackIcon class="ms-3" />

    <div class="position-relative">
      <img
        :src="subCategory.photoURL"
        class="sub-category-thumbnail w-100 rounded-3"
        oncontextmenu="return false"
      />
      <div class="text-white h4 position-absolute top-50 start-50 translate-middle">
        {{ subCategory.name }}
      </div>
    </div>

    <div style="margin: 16px">
      <ShowCard :spotData="historicSites" dataType="historic" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import ShowCard from '../components/parts/card/ShowCard.vue'

export default {
  components: {
    ShowCard,
  },
  data() {
    return {
      historicSites: [],
      subCategory: {},
    }
  },
  async created() {
    const firestore = firebase.firestore()
    const categoryRef = firestore.collection('categories').doc(this.$route.params.categoryId)
    const subCategorizationRef = categoryRef.collection('categorization')

    this.subCategory = await this.fetchSubCategoryInfo(categoryRef)
    const historicSiteIds = await this.filterHasHistryIds(subCategorizationRef)
    this.historicSites = await this.filterHasHistryInfo(historicSiteIds)

    if (this.subCategory?.order) {
      this.historicSites = this.orderedSpot(this.subCategory.order)
    }
  },
  methods: {
    fetchSubCategoryInfo(categoryRef) {
      return categoryRef
        .collection('subCategories')
        .doc(this.$route.params.subCategoryId)
        .get()
        .then((snapshot) => ({
          id: snapshot.id,
          ...snapshot.data(),
        }))
    },
    filterHasHistryIds(subCategorizationRef) {
      return subCategorizationRef
        .where('categoryId', '==', this.$route.params.subCategoryId)
        .orderBy('order')
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data().historicSiteId))
    },
    filterHasHistryInfo(historicSiteIds) {
      return this.$store.state.historicSites.filter((doc) => historicSiteIds.includes(doc.id))
    },
    orderedSpot(orderList) {
      return orderList.map((orderId) => {
        return this.historicSites.find((category) => category.id === orderId ?? '')
      })
    },
  },
}
</script>

<style scoped lang="scss">
.sub-category-thumbnail {
  height: 140px;
  object-fit: cover;
  filter: grayscale(30%);
}
</style>

<template>
  <div>
    <div id="coupon-modal-overlay"></div>
    <div class="coupon-modal">
      <button class="use" @click="useCoupon">使用確認</button>
      <div class="comment">
        <p>問題なければ使用確認ボタンを押してください</p>
      </div>
      <div class="coupon-img">
        <img :src="image" height="160px" width="311px" oncontextmenu="return false" />
      </div>
      <div class="box">
        <p class="presentation">この画面をお店の方に提示してください。</p>
        <p class="attention">
          ※使用確認ボタンを押すと、この画面が表示されなくなるのでご注意ください。
        </p>
      </div>
      <button class="cancel-btn" @click="returnTrue">キャンセル</button>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
export default {
  props: {
    image: {
      type: String,
      require: true,
    },
    couponId: {
      type: String,
      require: true,
    },
    shopId: {
      type: String,
      require: true,
    },
    shopTitle: {
      type: String,
      require: true,
    },
  },
  methods: {
    returnTrue() {
      this.$emit('closeModal')
    },
    useCoupon() {
      // クーポン使用処理
      firebase.firestore().collection('couponHistory').add({
        userId: this.$auth.currentUser.uid,
        couponId: this.couponId,
        shopId: this.shopId,
        shopTitle: this.shopTitle,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      this.$emit('closeCoupon')
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 2px;
}
// クーポンモーダル
.coupon-modal {
  width: 343px;
  height: 494px;
  padding: 5px 10px;
  background: $gururi-base-color;
  z-index: 2;
  display: block;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
#coupon-modal-overlay {
  z-index: 1;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
}
.use {
  width: 311px;
  height: 44px;
  border-radius: 100px;
  transform: scale(-1, -1);
  background: $gururi-brand-color;
  color: $gururi-base-color;
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  padding-top: 10px;
}
.comment {
  transform: scale(-1, -1);
  color: $sumi-700;
  font-size: 16px;
  background: $asagi-500;
  width: 100%;
  height: 49px;
  margin-top: 16px;
  border-radius: 100px;
  color: $gururi-base-color;
  font-weight: 700;
  font-size: 14px;
  padding-top: 13px;
}
.comment:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 100px;
  border: 7px solid transparent;
  border-top: 15px solid $asagi-500;
}
.coupon-img {
  margin-top: 18px;
  transform: scale(-1, -1);
}
.box {
  width: 270px;
}
.presentation {
  font-weight: 700;
  font-size: 14px;
  color: $sumi-800;
  margin-top: 36px;
}

.attention {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: $sumi-700;
  margin-top: 14px;
}
.cancel-btn {
  width: 100%;
  height: 44px;
  border-radius: 100px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 12px;
  border: 1px solid $gururi-brand-color;
  color: $gururi-brand-color;
  padding-top: 8px;
}
</style>

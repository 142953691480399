<template>
  <b-container class="sign-in-content">
    <BaseBackIcon />
    <form @submit.prevent="signIn">
      <h1 class="sign-in-title">おかえりなさい！</h1>
      <div class="sign-in-items">
        <div class="sign-in-item">
          <label for="email">メールアドレス</label>
          <div>
            <img :src="MAIL_IMG" class="input-icon" />
            <input
              type="email"
              placeholder="メールアドレス"
              v-model="signInEmail"
              id="email"
              required
            />
          </div>
        </div>

        <div class="sign-in-item">
          <label for="password"
            ><p class="m-0">パスワード</p>
            <span class="pass-sub-desc">※8文字以上の半角英数字を組み合わせる</span></label
          >
          <div>
            <img :src="PADLOCK_IMG" class="input-icon" />
            <input
              :type="toggleEyeIcon ? 'text' : 'password'"
              id="password"
              v-model="signInPassword"
              placeholder="パスワード"
              autocomplete="on"
              pattern="^[0-9A-Za-z]+$"
              minlength="8"
              required
            />
            <span @click="toggleEyeIcon = !toggleEyeIcon">
              <b-icon-eye v-if="toggleEyeIcon" />
              <b-icon-eye-slash v-if="!toggleEyeIcon" />
            </span>
          </div>
        </div>
      </div>
      <p style="color: red" class="mb-3">{{ errorMessage }}</p>
      <button type="submit" class="login">ログイン</button>
      <p class="reset-password">
        パスワードを忘れた方は<router-link to="/reset-password" class="reset-password-link"
          >こちら</router-link
        >
      </p>
    </form>
  </b-container>
</template>
<script>
import firebase from 'firebase'
import { MAIL_IMG, PADLOCK_IMG } from '../constants/img'

export default {
  data() {
    return {
      MAIL_IMG: MAIL_IMG,
      PADLOCK_IMG: PADLOCK_IMG,
      signInEmail: '',
      signInPassword: '',
      errorMessage: '',
      toggleEyeIcon: false,
    }
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.signInEmail, this.signInPassword)
        .then(async (userCredential) => {
          const userDocRef = firebase.firestore().collection('users').doc(userCredential.user.uid)
          const { user } = userCredential
          let actionCodeSettings = {}

          switch (location.hostname) {
            case process.env.VUE_APP_HOSTNAME_LOCAL:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_LOCAL_URL,
              }
              break
            case process.env.VUE_APP_HOSTNAME_MAIN:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_MAIN_URL,
              }
              break
            case process.env.VUE_APP_HOSTNAME_STAGING:
              actionCodeSettings = {
                url: process.env.VUE_APP_EMAIL_LINK_STAGING_URL,
              }
              break
          }

          await userDocRef.get().then(() => {
            if (user.emailVerified) {
              this.$swal({
                title: 'ログイン成功',
                icon: 'success',
                focusConfirm: false,
                confirmButtonColor: '#ec6d51',
              })
              this.$router.push({ path: '/' })
            } else {
              // 仮登録ユーザー
              user
                .sendEmailVerification(actionCodeSettings)
                .then(() => {
                  alert(
                    '本人確認のため、メールアドレスの認証をお願いいたします。入力されたメールアドレス宛に、認証用のメールをお送りしています。※迷惑メールに振り分けられていることがあるのでご注意ください。'
                  )
                })
                .catch((error) => {
                  alert(
                    '本人確認メールを送信できませんでした。しばらく時間をおいてから再度お試しください。既に入力されたメールアドレス宛に、認証用のメールをお送りしている可能性がございますので、ご確認ください。※迷惑メールに振り分けられていることがあるのでご注意ください。'
                  )
                  console.log(error)
                })
            }
          })
        })
        .catch((e) => {
          console.log(e)
          switch (e.code) {
            case 'auth/wrong-password':
              this.errorMessage = 'パスワードが違います'
              break
            case 'auth/user-not-found':
              this.errorMessage = 'ユーザーが存在しません'
              break
            case 'auth/too-many-requests':
              this.errorMessage =
                'このアカウントへのアクセスはログインに多数失敗したため、一時的に無効になっています。'
              break
            default:
              this.errorMessage = e.message
              break
          }
        })
    },
  },
}
</script>
<style scoped lang="scss">
.sign-in-content {
  width: 100%;
  padding: 0px 5%;
}
form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
}
.sign-in-title {
  color: #fa709a;
  font-size: 28px;
  margin-bottom: 10%;
}
.sign-in-items {
  text-align: left;

  .sign-in-item {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border-light;

    label {
      margin-bottom: 15px;
    }
  }
}
input {
  border: none;
  outline: none;
  width: 80%;
  margin-left: 1%;
}
.input-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.login {
  background-color: $gururi-base-color;
  width: 40%;
  height: 40px;
  color: $text-dark;
  border: 1px solid;
}
.pass-sub-desc {
  font-size: 0.8rem;
}
.reset-password {
  margin-top: 20px;
  &-link {
    color: $gururi-brand-color;
  }
}
</style>

<script>
import firebase from 'firebase'
import { formatToDate } from '../lib/formatToDate'

export default {
  created() {
    this.fetchMoment()
  },
  data() {
    return {
      showMoment: {},
      url: location.href,
      copyMessage: false,
    }
  },
  metaInfo() {
    return {
      title: this.showMoment.title,
      meta: [
        {
          property: 'og:title',
          content: this.showMoment.title,
        },
        {
          property: 'og:description',
          content: this.showMoment.text,
        },
        {
          property: 'og:url',
          content: this.url,
        },
        {
          property: 'og:image',
          content: this.showMoment.thumbnailPhotoBlob,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: this.showMoment.title,
        },
        {
          name: 'twitter:description',
          content: this.showMoment.text,
        },
        {
          name: 'twitter:image',
          content: this.showMoment.thumbnailPhotoBlob,
        },
      ],
    }
  },
  methods: {
    fetchMoment() {
      const LAST_PATH = 2
      const firestore = firebase.firestore()
      firestore
        .collection('moments')
        .doc(this.$route.path.split('/')[LAST_PATH])
        .get()
        .then((snapshot) => {
          this.showMoment = {
            title: snapshot.data().title,
            thumbnailPhotoBlob: snapshot.data().thumbnailPhotoBlob.replace(/^"(.*)"$/, '$1'),
            text: snapshot.data().text,
            momentsPhotoUrl: snapshot.data().momentsPhotoUrl,
            tags: snapshot.data().tags,
            userId: snapshot.data().userId,
            userName: snapshot.data().userName,
            userPhotoUrl: snapshot.data().userPhotoUrl,
            spotId: snapshot.data().spotId,
            spotTitle: snapshot.data().spotTitle,
            createdAt: formatToDate(snapshot.data().createdAt),
            logicalDeleteFlag: snapshot.data().logicalDeleteFlag,
          }
        })
    },
    moveUserPage(userId) {
      this.$router.push({ path: `/user/${userId}` })
    },
    onCopySuccess() {
      this.copyMessage = true
    },
    onCopyError(e) {
      alert(e.text + ' のコピーに失敗しました')
    },
    xShare() {
      const shareURL =
        'https://twitter.com/intent/tweet?' +
        'text=' +
        this.showMoment.title +
        '&url=' +
        this.url +
        '&hashtags=ぐるり'
      window.open(shareURL)
    },
    facebookShare() {
      const shareURL = 'http://www.facebook.com/share.php?' + '&u=' + this.url
      window.open(shareURL)
    },
  },
}
</script>

<template>
  <div>
    <!-- サムネイル -->
    <div style="position: relative">
      <img :src="showMoment.thumbnailPhotoBlob" alt="" class="thumbnail-img" />
      <div class="CF_subtitle-medium-text thumbnail-text">
        <p>
          {{ showMoment.createdAt }}
        </p>
        <p class="CF_heading-4 moment-title mt-2 mb-2">
          {{ showMoment.title }}
        </p>
        <div class="d-flex">
          <img
            :src="showMoment.userPhotoUrl"
            class="user-icon me-2"
            :alt="showMoment.userName"
            @click="moveUserPage(showMoment.userId)"
          />
          <p @click="moveUserPage(showMoment.userId)">
            {{ showMoment.userName }}
          </p>
        </div>
      </div>
    </div>

    <div class="moment-body text-left">
      <!-- 投稿テキスト -->
      <div class="CF_body-sub text-left post-text">
        <p>
          {{ showMoment.text }}
        </p>
      </div>

      <!-- 投稿画像 -->
      <div style="margin-bottom: 40px">
        <div
          v-for="(momentPhotoUrl, index) in showMoment.momentsPhotoUrl"
          :key="index"
          style="margin-bottom: 60px"
        >
          <img :src="momentPhotoUrl.photoUrl" :alt="momentPhotoUrl.altText" />
          <div class="count-moment-photo">
            <p class="CF_body-sub">
              {{ momentPhotoUrl.altText }}
            </p>
            <div class="CF_capture-regular-text photo-count">
              写真：{{ index + 1 }}/{{ showMoment.momentsPhotoUrl.length }}
            </div>
          </div>
        </div>
        <div class="share">
          <img
            src="../../public/images/link-icon.png"
            v-clipboard:copy="url"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          />
          <img src="../../public/images/x-icon.png" @click="xShare()" />
          <img src="../../public/images/facebook-icon.png" @click="facebookShare()" />
        </div>
        <div class="copy-message" v-if="copyMessage">
          <p>リンクがコピーされました</p>
        </div>
      </div>

      <!-- 行った史跡 -->
      <div v-if="1 == 0">
        <!-- TODO 行った史跡が一つも存在しない場合はタイトルから非表示にする -->
        <!-- TODO 行った史跡を配列にする。 -->
        <h2 class="CF_subtitle-bold-text">行った史跡</h2>
        <div></div>
      </div>

      <!-- 関連タグ -->
      <div v-if="showMoment.tags && showMoment.tags.length > 0" class="related-tag">
        <h2 class="CF_subtitle-bold-text">関連タグ</h2>
        <div class="moment-has-tags">
          <span
            v-for="(hasTag, index) in showMoment.tags"
            :key="index"
            class="CF_subtitle-medium-text has-tag"
            >#{{ hasTag }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.moment-has-tags {
  flex-wrap: wrap;
  display: flex;
}
.count-moment-photo {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.moment-body {
  margin: 24px 16px 60px;
}
.related-tag {
  margin-bottom: 100px;

  h2 {
    margin-bottom: 16px;
  }
  .has-tag {
    padding: 4px 10px;
    margin: 0px 8px 8px 0px;
    border-radius: 1000px;
    color: $text-medium;
    background-color: $bg-midium-gray;
  }
}
img {
  width: 100%;
}
.thumbnail-text {
  position: absolute;
  bottom: 5%;
  left: 16px;
  text-align: left;
  color: $text-white;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8); // デザイントークンに存在しないため直書き
  p {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6); // デザイントークンに存在しないため直書き
  }
}
.moment-title {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  color: $text-white;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); // デザイントークンに存在しないため直書き
}
.user-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5); // デザイントークンに存在しないため直書き
}
.text-left {
  text-align: left;
}
.photo-count {
  align-self: flex-start;
  white-space: nowrap;
  padding: 3px 8px;
  border-radius: 1000px;
  background-color: $bg-dark-gray;
  color: $text-white;
}
.share {
  display: flex;
  justify-content: center;
  img {
    width: 24px;
    margin: 0 20px;
  }
}
.copy-message {
  width: 210px;
  height: 40px;
  background-color: $bg-light-gray;
  border-radius: 4px;
  padding: 8px 20px;
  margin: 20px auto;
  p {
    color: $text-medium;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 175%; /* 24.5px */
    letter-spacing: 0.035px;
  }
}
.thumbnail-img {
  height: 295px;
  object-fit: cover;
}
.post-text {
  white-space: pre-line;
  margin-bottom: 60px;
}
</style>

<template>
  <div class="shop-area_list_wrapper">
    <div>
      <BaseBackIcon class="ms-3" />
      <Header />
      <p class="shop-area_list_ttl">{{ category.name }}</p>
    </div>
    <div class="shop-area_list_card">
      <div v-for="shop in shops" :key="shop.id">
        <div class="shop-area_list_card_thumbnail">
          <img
            class="shop-area_list_card_thumbnail_img"
            :src="shop.photoURL"
            alt="店舗のサムネイル画像"
          />
        </div>
        <div class="shop-area_list_card_textbox">
          <div class="shop-area_list_card_textbox_shopname">
            {{ shop.title }}
          </div>
          <div class="shop-area_list_card_textbox_shop-address">
            {{ shop.prefecture + shop.city + shop.address }}
          </div>
          <div class="shop-area_list_card_textbox_overviewtext">
            {{ shop.detail }}
          </div>
        </div>
        <div class="shop-area_list_card_footer">
          <router-link
            class="shop-area_list_card_footer_btn map"
            :to="{
              name: 'Shop',
              params: { id: shop.id },
            }"
            >マップを見る</router-link
          >
          <router-link
            class="shop-area_list_card_footer_btn info"
            :to="{
              name: 'ShowShop',
              params: { id: shop.id },
            }"
            >情報を見る</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import Header from '../components/layouts/Header.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {
      category: {},
      shops: [],
    }
  },
  created() {
    const firestore = firebase.firestore()

    // カテゴリ取得
    const categoryRef = firestore.collection('shopsCategories').doc(this.$route.params.id)
    categoryRef.get().then((snapshot) => {
      this.category = {
        id: snapshot.id,
        ...snapshot.data(),
      }
    })

    // 店舗取得
    const shopsRef = firestore.collection('shops')
    const categorizationRef = firestore.collection('shopsCategorization')
    categorizationRef
      .where('categoryId', '==', this.$route.params.id)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const shopId = doc.data().shopId
          shopsRef
            .doc(shopId)
            .get()
            .then((snapshot) => {
              this.shops.push({
                id: snapshot.id,
                ...snapshot.data(),
              })
            })
        })
      })
  },
}
</script>

<style scoped lang="scss">
template {
  font-family: 'Noto Sans JP', sans-serif;
}
.shop-area_list {
  &_wrapper {
    margin-bottom: 120px;
  }
  &_ttl {
    color: $text-dark;
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    padding-left: 16px;
    margin: 40px 0;
  }
  &_card {
    width: 343px;
    height: auto;
    margin: 0 auto 40px;
    background-color: $bg-light-gray;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    &_thumbnail {
      position: relative;
      width: 100%;
      height: 160px;
      background-color: $bg-dark-gray;
      &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::before {
        content: 'クーポンあり';
        position: absolute;
        width: 92px;
        height: 30px;
        left: 235px;
        top: 0px;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        color: $bg-light-gray;
        background-color: $gururi-brand-color;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
      }
    }
    &_textbox {
      text-align: left;
      padding: 8px 16px 20px;
      font-weight: 700;
      &_shopname {
        font-size: 24px;
        color: $text-dark;
      }
      &_shop-address {
        font-size: 14px;
        margin-top: 2px;
        color: $text-dark;
      }
      &_overviewtext {
        font-size: 12px;
        margin-top: 8px;
        color: $text-dark;
        font-weight: 500;
      }
    }
    &_footer {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;
      padding: 0 16px;
      justify-content: center;
      &_btn {
        width: 140px;
        padding: 10px 0;
        border-radius: 4px;
        color: $bg-light-gray;
        font-size: 14px;
        font-weight: 700;
        &.map {
          background-color: $gururi-brand-color;
        }
        &.info {
          background-color: #4d5aaf;
        }
      }
    }
  }
}
</style>

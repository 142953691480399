<script>
import firebase from 'firebase'
import imageCompression from 'browser-image-compression'
import { MOMENT_TAGS } from '../constants/code'
import {
  CHECK_IMG,
  CROSS_IMG,
  CLOSE_WHITE_IMG,
  ALTERNATE_IMG,
  ALTERNATE_DISABLED_IMG,
  EDIT_IMG,
} from '../constants/img'
import BackButton from '../components/parts/button/BackButton.vue'

export default {
  components: {
    BackButton,
  },
  data() {
    return {
      CHECK_IMG: CHECK_IMG,
      CROSS_IMG: CROSS_IMG,
      CLOSE_WHITE_IMG: CLOSE_WHITE_IMG,
      ALTERNATE_IMG: ALTERNATE_IMG,
      ALTERNATE_DISABLED_IMG: ALTERNATE_DISABLED_IMG,
      EDIT_IMG: EDIT_IMG,
      MAX_IMGS: 5,
      thumbnailTitle: '',
      thumbnailPhotoBlob: '',
      previewThumbnailPhotoUrl: '',
      text: '',
      beforeCompresseMomentsPhotos: [],
      momentsPhotoBlobList: [],
      tags: MOMENT_TAGS,
      hasTags: [],
      userInfo: {},
      isEditAltText: false,
      docId: '',
    }
  },
  methods: {
    // 画像を圧縮
    compressImg(imgFile) {
      return imageCompression(imgFile, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
      })
    },
    // fireStoregeへ画像をアップロード、アップロード先の画像を取得
    async uploadImg(compressedImg, kindImg) {
      const storageRef = firebase
        .storage()
        .ref()
        .child(`momentImages/${this.$auth.currentUser.uid}/${kindImg}/${compressedImg.name}`)
      await storageRef.put(compressedImg)
      return storageRef.getDownloadURL().then((photoUrl) => {
        return photoUrl
      })
    },
    // サムネイル画像の圧縮とプレビュー画像の生成
    async pushThumbnailPhotoUrl(e) {
      this.thumbnailPhotoBlob = await this.compressImg(e.target.files[0])
      this.previewThumbnailPhotoUrl = URL.createObjectURL(this.thumbnailPhotoBlob)
      this.fetchUserInfo()
    },
    // 投稿画像の圧縮とプレビュー画像の生成
    async pushMomentsPhotoUrlList(e) {
      if (this.beforeCompresseMomentsPhotos.length + e.target.files.length > this.MAX_IMGS) {
        alert(`登録できる写真の上限は${this.MAX_IMGS}枚です。`)
        return
      }
      Object.keys(this.$refs.preview.files).forEach(async (imageName) => {
        const compressedImg = await this.compressImg(this.$refs.preview.files[imageName])
        this.beforeCompresseMomentsPhotos.push({
          compressedPhoto: compressedImg,
          photoUrl: URL.createObjectURL(compressedImg),
          altText: '',
        })
      })
    },
    // ユーザーが入力した投稿情報をfirestoreへ登録する
    async postMoment(e) {
      e.preventDefault()
      if (!(this.thumbnailPhotoBlob && this.thumbnailTitle && this.text)) {
        alert('必須項目を入力してください')
        return
      }
      // fireStorageにサムネイルと投稿画像をアップロード
      this.thumbnailPhotoBlob = await this.uploadImg(this.thumbnailPhotoBlob, 'thumbnail')
      const momentsPhotoBlobList = this.beforeCompresseMomentsPhotos.map(async (momentUrl) => {
        return {
          photoUrl: await this.uploadImg(momentUrl.compressedPhoto, 'moment'),
          altText: momentUrl.altText,
        }
      })
      Promise.all(momentsPhotoBlobList).then((momentsPhotoUrl) => {
        const momentsRef = firebase.firestore().collection('moments')
        this.docId = momentsRef.doc().id
        momentsRef.doc(this.docId).set({
          id: this.docId,
          title: this.thumbnailTitle,
          thumbnailPhotoBlob: this.thumbnailPhotoBlob,
          text: this.text,
          momentsPhotoUrl: momentsPhotoUrl,
          tags: this.hasTags,
          userId: this.$auth.currentUser.uid,
          userName: this.userInfo.userName,
          userPhotoUrl: this.userInfo.photoUrl,
          // spotInfo: [
          //   {
          //     // spotId: 'aaa',
          //     // spotTitle: 'aaa',
          //     // TODO 史跡の画像も項目名に追加。史跡の画像も投稿時に追加。
          //     // spntImg: '',
          //   },
          // ],
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          logicalDeleteFlag: false,
        })
        alert('投稿しました')
        this.resetMoment()
      })
    },
    // 入力された投稿情報を削除
    resetMoment() {
      this.thumbnailPhotoBlob = ''
      this.previewThumbnailPhotoUrl = ''
      this.thumbnailTitle = ''
      this.text = ''
      this.hasTags = ''
      this.beforeCompresseMomentsPhotos = ''
    },
    // 投稿画像のalt文言の編集処理
    editAltText(e) {
      this.beforeCompresseMomentsPhotos.map((moment) => {
        if (moment.photoUrl == e.target.name) {
          moment.altText = e.target.value
        }
        return moment
      })
    },
    // ユーザー情報をfirestoreより取得
    fetchUserInfo() {
      firebase
        .firestore()
        .collection('users')
        .doc(this.$auth.currentUser.uid)
        .get()
        .then(async (snapshot) => {
          this.userInfo = {
            userName: snapshot.data().displayName,
            photoUrl: snapshot.data().photoURL,
          }
        })
    },
    // プレビューの画像を削除
    closePreviewImg(imgType, closeIndex) {
      if (!confirm('削除しますか？')) {
        return
      }
      if (imgType == 'thumbnail') {
        this.thumbnailPhotoBlob = ''
        this.previewThumbnailPhotoUrl = ''
      } else {
        this.beforeCompresseMomentsPhotos = this.beforeCompresseMomentsPhotos.filter(
          (photo, index) => index !== closeIndex
        )
      }
    },
  },
}
</script>

<template>
  <div class="text-left" style="margin: 6px 16px 90px">
    <form action="post">
      <div class="moment-header">
        <BackButton :buttonImg="CROSS_IMG" />
        <div>
          <label for="submit-btn" class="CF_button-medium-text submit">
            投稿する
            <input type="submit" @click="postMoment" id="submit-btn" style="display: none" />
          </label>
        </div>
      </div>

      <!-- サムネイル画像 -->
      <div>
        <div class="content-sub-title">
          <h2 class="CF_subtitle-regular-text">
            サムネイル
            <span class="CF_overline-regular-text required-text"> *必須</span>
          </h2>
        </div>
        <div v-if="previewThumbnailPhotoUrl" style="position: relative">
          <!-- 投稿画像をプレビューする -->
          <img :src="previewThumbnailPhotoUrl" style="margin-bottom: 8px" />
          <div class="close-img">
            <img
              :src="CLOSE_WHITE_IMG"
              alt="close-img"
              @click="closePreviewImg('thumbnail', (xxx = 0))"
            />
          </div>
        </div>
        <label v-if="!previewThumbnailPhotoUrl" for="thumbnail-btn" class="CB_button">
          <img :src="ALTERNATE_IMG" alt="photo-alternate" class="img-in-input" />
          <span class="CF_body-sub add-enabled-btn"> 写真を追加 </span>
          <input
            type="file"
            @change="pushThumbnailPhotoUrl"
            accept="image/*"
            id="thumbnail-btn"
            style="display: none"
            required
          />
        </label>
        <label v-else for="thumbnail-btn" class="CB_disabled_button">
          <img :src="ALTERNATE_DISABLED_IMG" alt="photo-alternate" class="img-in-input" />
          <span class="CF_body-sub add-disabled-btn"> 写真を追加 </span>
        </label>
      </div>

      <!-- 探訪機タイトル -->
      <div class="mt-20">
        <div class="content-sub-title">
          <h2 class="CF_subtitle-regular-text">
            探訪記タイトル
            <span class="CF_overline-regular-text">(45字以内)</span>
            <span class="CF_overline-regular-text required-text"> *必須</span>
          </h2>
        </div>
        <input
          v-model="thumbnailTitle"
          type="text"
          placeholder="タイトルを入力"
          maxlength="45"
          required
        />
      </div>

      <!-- テキスト -->
      <div class="mt-20">
        <div class="content-sub-title">
          <h2 class="CF_subtitle-regular-text">
            テキスト
            <span class="CF_overline-regular-text required-text"> *必須</span>
          </h2>
        </div>
        <textarea
          v-model="text"
          cols="30"
          rows="10"
          placeholder="行った史跡の感想や口コミを書いてみましょう。"
          required
        ></textarea>
      </div>

      <!-- 投稿画像 -->
      <div style="margin-top: 40px">
        <div v-if="beforeCompresseMomentsPhotos">
          <!-- 投稿画像をプレビューする -->
          <div
            v-for="(momentUrl, index) in beforeCompresseMomentsPhotos"
            :key="index"
            style="margin-bottom: 36px"
          >
            <div style="position: relative">
              <img :src="momentUrl.photoUrl" />
              <div v-if="!isEditAltText" style="margin-top: 8px">
                <span style="margin-right: 8px">
                  {{ momentUrl.altText == '' ? '説明を追加する' : momentUrl.altText }}
                </span>
                <img
                  :src="EDIT_IMG"
                  alt="edit"
                  class="img-in-input"
                  @click="isEditAltText = true"
                />
              </div>
              <input
                type="text"
                v-if="isEditAltText"
                @change="editAltText"
                @blur="isEditAltText = false"
                placeholder="説明を追加する"
                :name="momentUrl.photoUrl"
                :value="momentUrl.altText"
                style="margin-top: 8px"
              />
              <div class="close-img">
                <img :src="CLOSE_WHITE_IMG" alt="close-img" @click="closePreviewImg('', index)" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <label
            v-if="beforeCompresseMomentsPhotos.length != MAX_IMGS"
            for="moment-btn"
            class="CB_button"
          >
            <img :src="ALTERNATE_IMG" alt="photo-alternate" class="img-in-input" />
            <span class="CF_body-sub add-enabled-btn">写真を追加</span>
            <input
              type="file"
              ref="preview"
              @change="pushMomentsPhotoUrlList"
              id="moment-btn"
              accept="image/*"
              style="display: none"
              multiple
            />
          </label>
          <label v-else class="CB_disabled_button">
            <img :src="ALTERNATE_DISABLED_IMG" alt="photo-alternate" class="img-in-input" />
            <span class="CF_body-sub add-disabled-btn">写真を追加</span>
          </label>
          <div class="CF_overline-regular-text add-post-text">写真は5枚まで追加できます。</div>
        </div>
      </div>

      <!-- 行った場所 -->
      <div v-if="1 == 0" class="mt-20">
        <div class="content-sub-title">
          <h2 class="CF_subtitle-regular-text">
            行った場所
            <span class="CF_overline-regular-text"> (任意) </span>
          </h2>
          <div class="CF_overline-regular-text content-description">
            追加ボタンを押して史跡を追加してください。
          </div>
        </div>
        <input type="text" />
        <!-- TODO 行った場所の実装 -->
      </div>

      <!-- タグ -->
      <div class="mt-20">
        <div class="content-sub-title">
          <h2 class="CF_subtitle-regular-text">
            時代タグ
            <span class="CF_overline-regular-text"> (任意) </span>
          </h2>
          <div class="CF_overline-regular-text content-description">
            追加したい時代タグを選択してください。
          </div>
        </div>
        <div>
          <div class="moment-tags">
            <label v-for="(tag, index) in tags" :key="index" :for="tag" class="tags-label">
              <input
                type="checkbox"
                v-model="hasTags"
                :id="tag"
                name="check-button"
                :value="tag"
                style="display: none"
              />
              <span class="has-tag" :class="{ checked: hasTags.includes(tag) }"
                >{{ tag }}
                <img :src="CHECK_IMG" alt="" v-if="hasTags.includes(tag)" class="check-img" />
              </span>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.moment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.text-left {
  text-align: left;
}
.content-sub-title {
  margin-bottom: 12px;
}
.content-description {
  color: $text-light;
}
img {
  width: 100%;
}
.tags-label {
  margin: 0px 8px 16px 0px;
}
input[type='text'],
textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $border-light;
  padding: 12px 8px;
}
.mt-20 {
  margin-top: 20px;
}
.has-tag {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 1000px;
  color: $text-medium;
  background: $bg-light-gray;
}
.add-enabled-btn {
  color: $text-medium;
  text-align: center;
}
.add-disabled-btn {
  color: $text-disabled;
  text-align: center;
}
.submit {
  border-radius: 100px;
  background: $gururi-brand-color;
  color: $text-white;
  padding: 6px 12px;
}
.checked {
  color: $text-white;
  background: $bg-dark-gray;
}
.check-img {
  margin-left: 4px;
  width: 20px;
  height: 20px;
}
.img-in-input {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.close-img {
  border-radius: 1000px;
  background: $bg-dark-gray;
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
.add-post-text {
  margin-top: 8px;
  color: $text-medium;
  text-align: center;
}
.moment-tags {
  flex-wrap: wrap;
  display: flex;
}
.required-text {
  color: $semantic-error-danger;
}
</style>

<template>
  <div class="m-3 mt-5 pt-5 mb-3 pb-3">
    <Header class="pb-3" />
    <PrivacyPolicyContent class="text-start" />
  </div>
</template>

<script>
import Header from '../components/layouts/Header.vue'
import PrivacyPolicyContent from '../constants/content/PrivacyPolicyContent.vue'

export default {
  components: {
    Header,
    PrivacyPolicyContent,
  },
}
</script>

<template>
  <div class="show-historic-site">
    <BaseBackIcon />
    <DetailPage :spotData="historicSiteInfo" />
    <div class="post-moment-btn">
      <PostMomentButton />
    </div>
  </div>
</template>

<script>
import PostMomentButton from '../components/parts/button/PostMomentButton.vue'
import DetailPage from '../components/elements/DetailPage.vue'

export default {
  components: {
    PostMomentButton,
    DetailPage,
  },
  computed: {
    historicSiteInfo() {
      return this.$store.state.historicSites.filter((doc) => doc.id === this.$route.params.id)[0]
    },
  },
}
</script>

<style scoped lang="scss">
.show-historic-site {
  text-align: left;
  margin: 56px 16px 0px;
}
.post-moment-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 0px 16px 16px;
  background: $gururi-base-color;
}
</style>

<template>
  <div style="margin-bottom: 88px">
    <div>
      <h1 class="show-spot-title CF_heading-1">
        {{ spotData?.title }}
      </h1>
    </div>

    <div class="position-relative">
      <img
        :src="spotData?.photoURL"
        width="100%"
        height="220"
        style="border-radius: 10px; object-fit: cover"
        oncontextmenu="return false"
      />
      <p class="mt-3 CF_body-sub">
        {{ spotData?.detail }}
      </p>
    </div>

    <div class="spot-detail mb-5">
      <div>
        <div class="mb-3 spot-detail-box">
          <img :src="SHOP_POINT_IMG" width="22" height="22" class="me-3 detail-icon" />
          <p class="CF_subtitle-medium-text">
            {{ spotData?.prefecture + spotData?.city + spotData?.address }}
          </p>
        </div>

        <div class="mb-3 spot-detail-box" v-if="spotData?.businessHour">
          <img :src="SCHEDULE_IMG" width="22" height="22" class="me-3 detail-icon" />
          <p class="CF_subtitle-medium-text">
            {{ spotData?.businessHour }}
          </p>
        </div>

        <div class="mb-3 spot-detail-box" v-if="spotData?.businessHours">
          <img :src="SCHEDULE_IMG" width="22" height="22" class="me-3 detail-icon" />
          <div
            v-for="(businessHour, index) in spotData?.businessHours"
            class="CF_subtitle-medium-text"
            :key="index"
            v-html="businessHour"
          />
        </div>

        <div class="mb-3 spot-detail-box" v-if="spotData?.phone || spotData?.phones">
          <img :src="TELEPHONE_IMG" width="22" height="22" class="me-3 detail-icon" />
          <p class="CF_subtitle-medium-text">
            {{ spotData?.phone }}
          </p>
          <div v-if="checkType('ShowHistoricSite')" class="CF_subtitle-medium-text">
            <div v-if="typeof spotData?.phones == 'string'">
              <div>{{ spotData?.phones }}</div>
            </div>
            <div v-else>
              <div v-for="phone in spotData?.phones" :key="phone" v-html="phone" />
            </div>
          </div>
        </div>

        <div class="mb-3 spot-detail-box" v-if="spotData?.access || spotData?.accesses">
          <img :src="DIRECTIONS_WALK_IMG" width="22" height="22" class="me-3" />
          <p v-if="checkType('ShowShop')" class="CF_subtitle-medium-text">
            {{ spotData?.access }}
          </p>
          <div v-if="checkType('ShowHistoricSite')">
            <div
              v-for="access in spotData?.accesses"
              :key="access"
              v-html="access"
              class="CF_subtitle-medium-text"
            />
          </div>
        </div>

        <div class="mb-3 spot-url" v-if="spotData?.homepage">
          <img :src="LANGUAGE_IMG" width="22" height="22" class="me-3 detail-icon" />
          <a :href="spotData?.homepage">
            <p class="CF_subtitle-medium-text">
              {{ spotData?.homepage }}
            </p>
          </a>
        </div>
      </div>

      <!-- クーポン -->
      <div v-if="checkType('ShowShop')">
        <div v-if="$auth.currentUser.uid">
          <div v-for="coupon in coupons" :key="coupon.id">
            <router-link
              :to="{
                name: 'ShowCoupon',
                params: { id: coupon.id },
              }"
            >
              <b-button class="w-100 mb-3" pill variant="success"
                ><img :src="COUPON_IMG" class="me-3" alt="" />{{
                  coupon.name
                }}クーポンを表示する</b-button
              >
            </router-link>
          </div>
        </div>
        <div v-else v-b-modal.modal-beforeSignUp>
          <div v-for="coupon in coupons" :key="coupon.id">
            <b-button class="w-100 mb-3" pill variant="success"
              ><img :src="COUPON_IMG" class="me-3" alt="" />{{
                coupon.name
              }}クーポンを表示する</b-button
            >
          </div>
        </div>
      </div>

      <div class="move-map-btn" @click="moveMap(spotData?.id)">
        <img :src="checkType('ShowShop') ? MAP_ICON_WHITE_IMG : AUDIO_ICON_IMG" alt="" />
        <div class="CF_button-medium-text map-btn-text">
          {{ checkType('ShowShop') ? 'マップを表示する' : '音声ガイドを聞く' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

import {
  SHOP_POINT_IMG,
  SCHEDULE_IMG,
  TELEPHONE_IMG,
  DIRECTIONS_WALK_IMG,
  LANGUAGE_IMG,
  COUPON_IMG,
  MAP_ICON_WHITE_IMG,
  AUDIO_ICON_IMG,
} from '../../constants/img'

export default {
  props: {
    spotData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      SHOP_POINT_IMG: SHOP_POINT_IMG,
      SCHEDULE_IMG: SCHEDULE_IMG,
      TELEPHONE_IMG: TELEPHONE_IMG,
      DIRECTIONS_WALK_IMG: DIRECTIONS_WALK_IMG,
      LANGUAGE_IMG: LANGUAGE_IMG,
      COUPON_IMG: COUPON_IMG,
      MAP_ICON_WHITE_IMG: MAP_ICON_WHITE_IMG,
      AUDIO_ICON_IMG: AUDIO_ICON_IMG,
      spotType: this.$route.name,
      coupons: [],
    }
  },
  async created() {
    this.coupons = await this.fetchCouponInfo()
  },
  methods: {
    moveMap(spotId) {
      if (this.spotType === 'ShowShop') {
        this.$router.push({ path: `/shop/${spotId}`, name: 'Shop' })
      } else {
        this.$router.push({ path: `/historic-site/${spotId}`, name: 'HistoricSite' })
      }
    },
    checkType(spotTypeStr) {
      return this.spotType === spotTypeStr
    },
    fetchCouponInfo() {
      // クーポンを取得
      return firebase
        .firestore()
        .collection('coupons')
        .where('shopId', '==', this.$route.params.id)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return { id: doc.id, name: doc.data().name }
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
$filter-main-color: invert(55%) sepia(66%) saturate(2411%) hue-rotate(329deg) brightness(99%)
  contrast(87%);

a {
  display: inline-block;
  text-decoration: none;
}
.show-spot-title {
  text-align: left;
  margin-bottom: 8px;
}

.move-map-btn {
  display: flex;
  padding: 9px 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: $gururi-accent-color;
}
.map-btn-text {
  color: $text-white;
}

button {
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.8rem 2rem;
  border-radius: 2px;
}

.spot-detail {
  margin: 16px auto 0px;
  padding: 0px 16px;
  text-align: left;

  .spot-detail-box {
    display: flex;
  }

  .spot-url {
    word-break: break-all;
    max-width: 100%;
    display: flex;
  }

  .detail-icon {
    filter: $filter-main-color;
  }
}
</style>

<template>
  <b-modal id="privacy-policy" hide-header hide-footer>
    <div @click="$bvModal.hide('privacy-policy')" class="pb-3 text-end">
      <img :src="CLOSE_IMG" />
    </div>
    <PrivacyPolicyContent />
    <span @click="$bvModal.hide('privacy-policy')">
      <Button buttonText="閉じる" />
    </span>
  </b-modal>
</template>

<script>
import Button from '../parts/button/Button.vue'
import PrivacyPolicyContent from '../../constants/content/PrivacyPolicyContent.vue'
import { CLOSE_IMG } from '../../constants/img'

export default {
  components: {
    PrivacyPolicyContent,
    Button,
  },
  data() {
    return {
      CLOSE_IMG: CLOSE_IMG,
    }
  },
}
</script>

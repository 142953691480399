<template>
  <l-marker
    :lat-lng="[shop.latLng.latitude, shop.latLng.longitude]"
    @click="
      $router.push(
        {
          name: 'Shop',
          params: { id: shop.id },
        },
        () => {}
      )
    "
    ref="shopMarker"
  >
    <l-icon
      :icon-url="mapIconDisplay(shop.shopTypesId)"
      class-name="shopIcon"
      :icon-size="icon.iconSize"
    />
    <l-popup>
      <p hidden ref="shopId">{{ shop.id }}</p>
      <div class="shop-popup">
        <img :src="shop.photoURL" oncontextmenu="return false" />
        <div class="shop-popup-text">
          <h3 style="margin-top: 0">{{ shop.title }}</h3>
          <router-link
            :to="{
              name: 'ShowShop',
              params: { id: shop.id },
            }"
          >
            <div>
              <span class="popup-button show-button"> 詳細を見る </span>
            </div>
          </router-link>
          <div>
            <div v-if="storeVisitedSpotIds.includes(shop.id)">
              <!-- 訪問済み -->
              <span class="popup-button disabled"> 訪問スタンプを押す </span>
            </div>
            <div v-else>
              <div v-if="$auth.currentUser.uid">
                <!-- ログイン済み 未訪問 -->
                <span class="popup-button" @click="$emit('compareCurrentAndVisited')">
                  訪問スタンプを押す
                </span>
              </div>
              <div v-else>
                <!-- 未ログイン -->
                <span class="popup-button" v-b-modal.modal-beforeSignUp> 訪問スタンプを押す </span>
              </div>
            </div>
          </div>
          <div v-if="storeVisitedSpotIds.includes(shop.id)" style="text-align: right">
            <img :src="VISITED_HANKO_IMG" class="visited-hanko" />
          </div>
          <div v-else style="text-align: right">
            <img :src="NOT_VISITED_HANKO_IMG" class="visited-hanko" />
          </div>
        </div>
      </div>
    </l-popup>
    <l-tooltip
      :options="{
        permanent: true,
        opacity: 0.8,
        offset: [10, 10],
        interactive: true,
      }"
    >
      <div>{{ shop.title }}</div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LMarker, LPopup, LTooltip, LIcon } from 'vue2-leaflet'
import {
  SHOP_ICON_IMG,
  WATCH_PLAY_ICON_IMG,
  ICON_BUY_RED_ICON_IMG,
  VISITED_HANKO_IMG,
  NOT_VISITED_HANKO_IMG,
} from '../../constants/img'

export default {
  components: {
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
  },
  props: {
    shop: {
      type: Object,
      require: true,
    },
    icon: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      SHOP_ICON_IMG: SHOP_ICON_IMG,
      WATCH_PLAY_ICON_IMG: WATCH_PLAY_ICON_IMG,
      ICON_BUY_RED_ICON_IMG: ICON_BUY_RED_ICON_IMG,
      VISITED_HANKO_IMG: VISITED_HANKO_IMG,
      NOT_VISITED_HANKO_IMG: NOT_VISITED_HANKO_IMG,
    }
  },
  created() {
    if (this.$route.name == 'Shop') {
      this.openShopPopup(this.$route.params.id)
    }
  },
  methods: {
    openShopPopup(currentShopId) {
      this.$nextTick(() => {
        if (!this.$refs.shopId) return
        if (currentShopId == this.$refs.shopId.innerHTML) {
          this.$refs.shopMarker.mapObject.openPopup()
        }
      })
    },
    mapIconDisplay(shopTypesId) {
      // アイコンをデータベースに保存させる
      if ('22zByFqTRB4NFk3CkDjB' == shopTypesId) {
        return WATCH_PLAY_ICON_IMG
      } else if ('orqx49sJqOCrVrDQ0SFQ' == shopTypesId) {
        return SHOP_ICON_IMG
      } else {
        return ICON_BUY_RED_ICON_IMG
      }
    },
  },
  computed: {
    // 訪問済みスポットのIDを保持
    storeVisitedSpotIds() {
      return this.$store.state.visitedSpotIds.map((doc) => doc.visitedSpotId)
    },
  },
}
</script>

<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
a {
  display: inline-block;
  cursor: pointer;
}

h3 {
  font-size: 16px;
  font-family: 'Noto Sans JP', sans-serif;
}

.shop-popup {
  display: flex;
  width: 266px;

  img {
    width: 70px;
    border-radius: 10px;
    object-fit: cover;
  }
  .shop-popup-text {
    margin-left: 15px;
  }
  .visited-hanko {
    position: absolute;
    right: -7%;
    bottom: -10%;
    width: 67px;
  }
}

.popup-button {
  display: inline-block;
  border-radius: 3px;
  margin: 0 auto;
  max-width: 220px;
  padding: 8px 16px;
  color: $gururi-base-color;
  font-weight: 500;
  background: $gururi-brand-color;
  cursor: pointer;
}
.disabled {
  background: $bg-midium-gray;
  cursor: text;
}
.show-button {
  background: $asagi-600;
  margin-bottom: 8px;
}
</style>

<template>
  <b-button pill variant="outline-danger" class="w-100 mt-3 mb-3">
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      require: true,
    },
  },
}
</script>

<style scoped lang="scss">
.btn-outline-danger {
  color: $gururi-brand-color;
  border-color: $gururi-brand-color;
  &:hover {
    color: $gururi-base-color;
    border-color: $gururi-brand-color;
    background-color: $gururi-brand-color;
  }
}
</style>

<template>
  <div class="profile">
    <!-- プロフィール表示 -->
    <div class="profile-wrapper" v-if="!isEditedProfile">
      <div class="profile-inner">
        <BaseBackIcon />
        <div class="my-profile">
          <div class="my-profile__image">
            <img :src="user.photoURL" />
          </div>
          <div class="my-profile__info">
            <div class="user-name">{{ user.displayName }}</div>
            <div>{{ user.profile }}</div>
          </div>
        </div>
        <div v-if="$auth.currentUser.uid == user.uid" class="option-btn">
          <button @click="editProfile" class="edit-btn">プロフィールを編集する</button>
        </div>
      </div>

      <!-- ユーザー毎の探訪記一覧 -->
      <div class="review-list">
        <div style="display: flex; justify-content: space-around">
          <div class="review-list-title" @click="isTab = true">
            <p class="CF_subtitle-medium-text" :class="!isTab ? 'noncurrent-text' : ''">探訪記</p>
            <div
              class="CF_overline-regular-text review-count"
              :class="isTab ? 'current-icon' : 'noncurrent-icon'"
            >
              {{ myMomentList.length }}
            </div>
            <div :class="isTab ? 'current-tab' : 'noncurrent-icon'"></div>
          </div>
          <div class="review-list-title" @click="isTab = false">
            <p class="CF_subtitle-medium-text" :class="isTab ? 'noncurrent-text' : ''">訪問履歴</p>
            <div
              class="CF_overline-regular-text review-count"
              :class="!isTab ? 'current-icon' : 'noncurrent-icon'"
            >
              {{ myVisitedList.length }}
            </div>
            <div :class="!isTab ? 'current-tab' : ''"></div>
          </div>
          <div class="underline"></div>
        </div>
        <div v-if="isTab" class="review-list__items">
          <div v-for="moment in myMomentList" :key="moment.id" class="my-moment">
            <div @click="moveShowMoment(moment.id)">
              <img :src="moment.thumbnailPhotoBlob" alt="サムネイル画像" />
              <p class="CF_subtitle-bold-text thumbnail-title">{{ moment.title }}</p>
            </div>
          </div>
          <div v-if="myMomentList.length == 0" class="no-showmoment">
            <img :src="NO_SHOWMOMENT_IMG" alt="探訪記を書きはじめる" />
            <p class="CF_body-main">あなたの歴史旅の記録を綴ってみましょう。</p>
            <button class="CF_button-bold-text" @click="$router.push({ path: '/post-moment/' })">
              探訪記を書きはじめる
            </button>
            <a class="CF_button-medium-text" @click="$router.push({ path: '/moment-list/' })"
              >みんなの探訪記を読んでみる</a
            >
          </div>
          <!-- <div class="post-moment-btn">
            <PostMomentButton />
          </div> -->
        </div>

        <!-- 訪問履歴一覧 -->
        <div v-else class="review-list__items">
          <div v-for="visitedSpot in myVisitedList" :key="visitedSpot.id" class="review-list__item">
            <div class="review-list__item__right">
              <router-link
                :to="{
                  name: visitedSpot.typeName == 'ShowShop' ? 'ShowShop' : 'ShowHistoricSite',
                  params: {
                    id: visitedSpot.id,
                  },
                }"
              >
                <img :src="visitedSpot.photoURL" class="" />
              </router-link>
            </div>
            <div class="review-list__item__left">
              <router-link
                :to="{
                  name: visitedSpot.typeName == 'ShowShop' ? 'ShowShop' : 'ShowHistoricSite',
                  params: {
                    id: visitedSpot.id,
                  },
                }"
              >
                <div>
                  <p class="historic-site-title">
                    {{ visitedSpot.title }}
                  </p>
                </div>
                <div>
                  <p style="color: rgb(158, 150, 137); font-size: 12px">
                    {{ visitedSpot.visitedTime }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-if="myVisitedList.length == 0" class="no-visit-record">
            <img :src="NO_VISITRECORD_IMG" alt="史跡" class="no-visit-record__img" />
            <p class="CF_body-main no-visit-record__text">まだ訪問記録がありません。</p>
            <p class="no-visit-record__push-stamp-text">
              スポットに近づき、マップ画面上の訪問スタンプを<br />押すと記録されます。
            </p>
            <img
              :src="PUSH_STAMP_VISIT_IMG"
              alt="訪問スタンプの利用について"
              class="no-visit-record__push-stamp-img"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- プロフィール編集 -->
    <div v-else>
      <div
        class="back-btn material-icons icon-outlined d-flex align-items-center"
        @click="cancelEditedProfile"
      >
        arrow_back_ios
      </div>
      <form @submit.prevent="updateProfile">
        <div class="profile-edit">
          <div class="profile-edit__image">
            <b-icon-plus class="plus-position" />
            <input
              type="file"
              @change="profileImage"
              style="display: none"
              ref="profileImage"
              accept="image/*"
            />
            <img :src="user.photoURL" @click="$refs.profileImage.click()" style="opacity: 0.5" />
          </div>
          <div class="basic-info">
            <p class="item-title">基本情報</p>
            <div class="basic-info__items">
              <label :class="inputClass" id="name">
                <p>名前</p>
                <input
                  type="text"
                  placeholder="ユーザー名"
                  for="name"
                  maxlength="15"
                  @focus="onFocus"
                  @blur="onBlur"
                  v-model="user.displayName"
                  required
                />
              </label>
            </div>
          </div>
          <div class="self-introduction">
            <p class="item-title">自己紹介 (30文字以内)</p>
            <div class="self-introduction__text">
              <textarea rows="5" cols="33" v-model="user.profile" maxlength="30"> </textarea>
            </div>
          </div>
          <button type="submit" class="profile-edit-btn">更新する</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import imageCompression from 'browser-image-compression'
import { formatToDate } from '../lib/formatToDate'
import { NO_SHOWMOMENT_IMG, NO_VISITRECORD_IMG, PUSH_STAMP_VISIT_IMG } from '../constants/img'

export default {
  components: {
    // PostMomentButton,
  },
  data() {
    return {
      user: [],
      isEditedProfile: false,
      isTab: true,
      visitedSpotsInfo: [],
      inputClass: 'basic-info__item',
      myMomentList: [],
      myVisitedList: [],
      NO_SHOWMOMENT_IMG: NO_SHOWMOMENT_IMG,
      NO_VISITRECORD_IMG: NO_VISITRECORD_IMG,
      PUSH_STAMP_VISIT_IMG: PUSH_STAMP_VISIT_IMG,
    }
  },
  async created() {
    this.myMomentList = await this.fetchMyMoment()
    this.myVisitedList = await this.fetchMyVisitedList(this.$store.state.historicSites)

    const shopsInfo = await this.$store.state.shops.filter((item) =>
      this.$store.state.visitedSpotIds.map((doc) => doc.visitedSpotId).includes(item.id)
    )
    const shopsInfoAddTypeName = await shopsInfo.map((item) => {
      return { ...item, typeName: 'ShowShop' }
    })

    const HistInfo = await this.$store.state.historicSites.filter((item) =>
      this.$store.state.visitedSpotIds.map((doc) => doc.visitedSpotId).includes(item.id)
    )
    this.visitedSpotsInfo = [...shopsInfoAddTypeName, ...HistInfo]

    // スポットへの訪問時間をvisitedSpotsInfoに追記
    this.visitedSpotsInfo = await this.visitedSpotsInfo.map((info) => {
      const matchingDoc = this.$store.state.visitedSpotIds.find(
        (doc) => doc.visitedSpotId === info.id
      )
      return {
        ...info,
        visitedTime: `${formatToDate(matchingDoc.createdAt)} に訪問`,
      }
    })

    // ユーザー情報取得
    firebase
      .firestore()
      .collection('users')
      .doc(this.$route.params.id)
      .get()
      .then(async (snapshot) => {
        this.user = {
          ...snapshot.data(),
        }
      })

    // 修正が必要  ※別タスクで実施
    //   firebase
    //     .firestore()
    //     .collection('shops')
    //     .doc(doc.data().shopId)
    //     .get()
    //     .then((shopSnapshot) => {
    //       this.comments.push({
    //         shopId: shopSnapshot.id,
    //         historicSitePhotoURL: shopSnapshot.data().photoURL,
    //         historicSiteTitle: shopSnapshot.data().title,
    //         historicSiteAddress:
    //           shopSnapshot.data().prefecture +
    //           shopSnapshot.data().city +
    //           shopSnapshot.data().address,
    //         ...doc.data(),
    //         id: doc.id,
    //       })
    //     })
  },
  methods: {
    updateProfile() {
      // authenticationに登録
      firebase.auth().currentUser.updateProfile({
        displayName: this.user.displayName,
        photoURL: this.user.photoURL,
      }),
        // Usersテーブルをupdate
        firebase.firestore().collection('users').doc(this.$auth.currentUser.uid).update(this.user)
      this.isEditedProfile = false
    },
    // プロフィール編集切り替え
    editProfile() {
      this.isEditedProfile = true
    },
    cancelEditedProfile() {
      this.isEditedProfile = false
    },
    // プロフィール画像をstorageにあげる
    async profileImage(e) {
      const file = await imageCompression(e.target.files[0], {
        maxSizeMB: 1,
        maxWidthOrHeight: 400,
        useWebWorker: true,
      })
      const fileRef = firebase
        .storage()
        .ref()
        .child('photoImages/' + this.$auth.currentUser.uid)
      fileRef.put(file)
      fileRef.getDownloadURL().then((url) => {
        this.user.photoURL = url
      })
    },

    onFocus() {
      this.inputClass = 'basic-info__item border-blue'
    },
    onBlur() {
      this.inputClass = 'basic-info__item'
    },
    async fetchMyMoment() {
      const LAST_PATH = 2
      const firestore = firebase.firestore()
      const snapshot = await firestore
        .collection('moments')
        .where('userId', '==', this.$route.path.split('/')[LAST_PATH])
        .where('logicalDeleteFlag', '==', false)
        .get()
      return snapshot.docs
        .map((doc) => {
          return {
            id: doc.data().id,
            title: doc.data().title,
            thumbnailPhotoBlob: doc.data().thumbnailPhotoBlob,
            userId: doc.data().userId,
            userName: doc.data().userName,
            userPhotoUrl: doc.data().userPhotoUrl,
            createdAt: formatToDate(doc.data().createdAt),
          }
        })
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    },
    moveShowMoment(momentId) {
      this.$router.push({ path: `/show-moment/${momentId}` })
    },
    async fetchMyVisitedList(historicSites) {
      const LAST_PATH = 2
      const firestore = firebase.firestore()
      const snapshot = await firestore
        .collection('users')
        .doc(this.$route.path.split('/')[LAST_PATH])
        .collection('visitedHistory')
        .get()
      if (historicSites.length !== 0) {
        // 初回アクセス時
        return snapshot.docs.map((doc) => {
          const historicSite = historicSites.find((item) => item.id === doc.data().visitedSpotId)
          return {
            id: doc.data().visitedSpotId,
            title: historicSite.title,
            photoURL: historicSite.photoURL,
            visitedTime: `${formatToDate(doc.data().createdAt)} に訪問`,
            typeName: 'ShowHistoricSite',
          }
        })
      } else {
        // リロード時
        return Promise.all(
          snapshot.docs.map(async (doc) => {
            const historicSite = await firestore
              .collection('historicSites')
              .doc(doc.data().visitedSpotId)
              .get()
            return {
              id: doc.data().visitedSpotId,
              title: historicSite.data().title,
              photoURL: historicSite.data().photoURL,
              visitedTime: `${formatToDate(doc.data().createdAt)} に訪問`,
              typeName: 'ShowHistoricSite',
            }
          })
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* 共通パーツ
---------------------------- */
$white: $gururi-base-color;
$black: $text-dark;
$twitter-color: #00a3ff;

a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 2px;
}
// img {
//   width: 100%;
//   aspect-ratio: 16/9;
//   border-radius: 4px;
//   object-fit: cover;
// }

/* マイページ
---------------------------- */
.profile {
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  &__back {
    text-align: left;
    a {
      color: $gururi-brand-color;
    }
  }
}
.profile-wrapper {
  margin: 0 auto;
  width: 100%;
}
.profile-inner {
  padding: 0px 6%;
}
.my-profile {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
  &__image {
    margin-left: 20px;
    flex: 1;
    img {
      border-radius: 50%;
      object-fit: cover;
      height: 110px;
      width: 110px;
    }
  }
  &__info {
    flex: 2;
    padding: 0 20px;
    text-align: left;
    .user-name {
      margin-bottom: 9px;
    }
  }
}
.option-btn {
  display: flex;
  justify-content: space-around;
  margin: 10px auto 20px;
  width: 90%;
  .edit-btn {
    border: 1px solid $twitter-color;
    color: $twitter-color;
    transition: all 0.1s;
    padding: 0.4rem 1rem;
  }
}
.review-list {
  padding: 25px 0 120px;
  &__item {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    &__right {
      flex: 1;
      img {
        border-radius: 50%;
        object-fit: cover;
        height: 90px;
        width: 90px;
      }
    }
    &__left {
      flex: 2;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .historic-site-title {
        color: $black;
      }
    }
  }
  &__items {
    padding: 0px 6%;
    .no-showmoment {
      text-align: center;
      img {
        width: 156px;
      }
      p {
        margin-top: 36px;
      }
      button {
        color: $text-white;
        width: 208px;
        height: 36px;
        border-radius: 1000px;
        background-color: $gururi-brand-color;
        padding: 10px 20px;
        line-height: 100%;
        margin-top: 20px;
      }
      a {
        color: $gururi-brand-color;
        margin-top: 20px;
        display: block;
      }
      a::after {
        content: '';
        width: 7px;
        height: 7px;
        border-right: 1px solid $gururi-brand-color;
        border-top: 1px solid $gururi-brand-color;
        display: inline-block;
        transform: rotate(45deg);
        top: -2px;
        left: 2px;
        position: relative;
      }
    }
    .no-visit-record {
      &__img {
        width: 170px;
        height: 146px;
      }
      &__text {
        margin-top: 24px;
      }
      &__push-stamp-text {
        color: $text-medium;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%; /* 24.5px */
        letter-spacing: 0.05px;
      }
      &__push-stamp-img {
        width: 267px;
        height: 167px;
        margin-top: 24px;
      }
    }
  }
}
.review-list-title {
  text-align: center;
  margin-bottom: 25px;
  width: 50%;
  padding-bottom: 5px;
  position: relative;
  padding-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-count {
  margin-left: 8px;
  width: 19px;
  height: 19px;
  background: $bg-dark-gray;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-white;
}
.current-tab {
  width: 100%;
  height: 4px;
  flex-shrink: 0;
  background: $gururi-brand-color;
  position: absolute;
  left: 0;
  bottom: 0;
}
.noncurrent-text {
  color: $sumi-400;
}
.current-icon {
  background: $gururi-brand-color;
}
.noncurrent-icon {
  background-color: $bg-midium-gray;
}

/* プロフィール編集
---------------------------- */
.profile-edit {
  width: 80%;
  margin: 0 auto;
  width: 100%;
  &__image {
    margin: 20px 0 30px;
    position: relative;
    .plus-position {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 5rem;
      transform: translate(-50%, -50%);
      color: #565656;
    }

    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
  }
  .item-title {
    text-align: left;
    background: $bg-light-gray;
    padding: 5px 10px;
    color: $text-medium;
  }
  .basic-info {
    &__items {
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      width: 87%;
    }
    &__item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      border-bottom: 1px solid $border-midium;
      padding-bottom: 5px;
      align-items: center;

      p {
        margin-right: 30px;
      }

      input {
        font-size: 1.1rem;
        outline: none;
        border: none;
      }
    }
  }
  .self-introduction {
    margin: 20px 0;
    &__text {
      width: 85%;
      margin: 0 auto;
    }

    textarea {
      font-size: 1.1rem;
      width: 100%;
      margin-top: 20px;
    }
  }
  .profile-edit-btn {
    background-color: $gururi-brand-color;
    color: $gururi-base-color;
  }
}
.back-btn {
  color: $bg-dark-gray;
  height: 48px;
}
.my-moment {
  margin-top: 16px;
  img {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 4px;
    object-fit: cover;
  }
}
.thumbnail-title {
  margin-top: 8px;
  text-align: left;
  color: $text-dark;
}
.post-moment-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 0px 16px 16px;
  background: $gururi-base-color;
}
</style>

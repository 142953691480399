<template>
  <b-modal id="user-term" hide-header hide-footer>
    <div @click="$bvModal.hide('user-term')" class="pb-3 text-end">
      <img :src="CLOSE_IMG" />
    </div>
    <UserTermContent />
    <span @click="$bvModal.hide('user-term')">
      <Button buttonText="閉じる" />
    </span>
  </b-modal>
</template>

<script>
import Button from '../parts/button/Button.vue'
import UserTermContent from '../../constants/content/UserTermContent.vue'
import { CLOSE_IMG } from '../../constants/img'

export default {
  components: {
    UserTermContent,
    Button,
  },
  data() {
    return {
      CLOSE_IMG: CLOSE_IMG,
    }
  },
}
</script>

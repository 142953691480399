<script>
import { EDIT_WHITE_IMG } from '../../../constants/img'

export default {
  data() {
    return {
      EDIT_WHITE_IMG: EDIT_WHITE_IMG,
    }
  },
}
</script>

<template>
  <div v-if="$auth.currentUser.uid">
    <div class="moment-btn" @click="$router.push({ path: '/post-moment' })">
      <p>探訪記を書く</p>
      <img :src="EDIT_WHITE_IMG" alt="編集アイコン" />
    </div>
  </div>
  <div v-else>
    <div class="moment-btn" v-b-modal.modal-beforeSignUp>
      <p>探訪記を書く</p>
      <img :src="EDIT_WHITE_IMG" alt="編集アイコン" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.moment-btn {
  // TODO デザイントークンに変更
  border-radius: 8px;
  background: $gururi-brand-color;
  display: flex;
  width: 100%;
  height: 56px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  p {
    color: $text-white;
    font-feature-settings: 'pwid' on;

    /* Button/bold text */
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: 0.64px;
  }
}
</style>

import CLOSE from '../../public/images/close.png'
import CLOSE_BG_WHITE from '../../public/images/close_bg_white.svg'
import HORIZON from '../../public/images/more_horiz.png'
import DELETE from '../../public/images/delete.png'
import GURURI_LOGO from '../../public/images/gururi-logo.png'
import NEW_GURURI_LOGO from '../../public/images/gururi-logo-new.png'
import MAKIMONO from '../../public/images/SoundSiteIcon.png'
import LAYER from '../../public/images/layer-icon(orange).png'
import CURRENT from '../../public/images/current-location(orange).png'
import USER_ICON from '../../public/images/default-user-icon.svg'
import WARLORD from '../../public/images/warlord.png'
import SHOP_POINT from '../../public/images/shop-point.svg'
import SCHEDULE from '../../public/images/schedule.svg'
import TELEPHONE from '../../public/images/telephone.svg'
import DIRECTIONS_WALK from '../../public/images/directions_walk.svg'
import LANGUAGE from '../../public/images/language.svg'
import COUPON from '../../public/images/coupon-icon.png'
import MAIL from '../../public/images/mail.png'
import PADLOCK from '../../public/images/key.png'
import SHOP_ICON from '../../public/images/shop-icon.png'
import WATCH_PLAY_ICON from '../../public/images/watch-play.png'
import ICON_BUY_RED_ICON from '../../public/images/icon_buy-red.png'
import VISITED_HANKO from '../../public/images/visited-hanko.png'
import VISITED_HANKO_BLUE from '../../public/images/visited-spot-stamp.png'
import NOT_VISITED_HANKO from '../../public/images/not-visited-hanko.png'
import PLAY from '../../public/images/play.svg'
import PAUSE from '../../public/images/pause.svg'
import CHECK from '../../public/images/check.png'
import CROSS_MARK from '../../public/images/cross-mark.svg'
import CLOSE_WHITE from '../../public/images/close-white.svg'
import ADD_PHOTO_ALTERNATE from '../../public/images/add_photo_alternate.svg'
import ADD_PHOTO_ALTERNATE_DISABLED from '../../public/images/add_photo_alternate_disabled.svg'
import EDIT from '../../public/images/edit.svg'
import EDIT_WHITE from '../../public/images/edit-white.svg'
import DOCS_ICON from '../../public/images/docs-icon.svg'
import HELP_ICON from '../../public/images/help-icon.svg'
import HOME_ICON from '../../public/images/home-icon.svg'
import MAP_ICON from '../../public/images/map-icon.svg'
import PRIVACY_POLICY_ICON from '../../public/images/security-icon.svg'
import HAMBURGER_MENU from '../../public/images/hamburger.svg'
import LOGOUT from '../../public/images/logout.svg'
import SHOW_MOMENT from '../../public/images/showMoment.svg'
import BACK from '../../public/images/arrow_back.svg'
import MAP_ICON_WHITE from '../../public/images/map-icon-white.svg'
import AUDIO_ICON from '../../public/images/audio-icon.svg'
import NO_SHOWMOMENT from '../../public/images/no-show-moment.png'
import NO_VISITRECORD from '../../public/images/no-visit-record.png'
import PUSH_STAMP_VISIT from '../../public/images/push-stamp-visit.png'
import KANAZAWAKU_THUMBNAIL from '../../public/images/kanazawaku_thumbnail.png'
import EVENTPAGE_COURSE_TITLE_BACKGROUND from '../../public/images/eventpage-course-title-background.png'
import EVENTPAGE_VISITED1 from '../../public/images/eventpage-visited1.png'
import EVENTPAGE_VISITED2 from '../../public/images/eventpage-visited2.png'
import EVENTPAGE_VISITED3 from '../../public/images/eventpage-visited3.png'
import EXTERNAL_LINK_ICON from '../../public/images/external-link-icon.svg'
import OPEN_KANAZAWA_EVENT from '../../public/images/open-kanazawa-event.png'
import BEFORE_KANAZAWA_EVENT from '../../public/images/before-kanazawa-event.png'

export const CLOSE_IMG = CLOSE
export const CLOSE_WHITE_IMG = CLOSE_WHITE
export const CLOSE_BG_WHITE_IMG = CLOSE_BG_WHITE
export const HORIZON_IMG = HORIZON
export const DELETE_IMG = DELETE
export const GURURI_LOGO_IMG = GURURI_LOGO
export const NEW_GURURI_LOGO_IMG = NEW_GURURI_LOGO
export const MAKIMONO_IMG = MAKIMONO
export const LAYER_IMG = LAYER
export const CURRENT_IMG = CURRENT
export const USER_ICON_IMG = USER_ICON
export const WARLORD_IMG = WARLORD
export const SHOP_POINT_IMG = SHOP_POINT
export const SCHEDULE_IMG = SCHEDULE
export const TELEPHONE_IMG = TELEPHONE
export const DIRECTIONS_WALK_IMG = DIRECTIONS_WALK
export const LANGUAGE_IMG = LANGUAGE
export const COUPON_IMG = COUPON
export const MAIL_IMG = MAIL
export const PADLOCK_IMG = PADLOCK
export const VISITED_HANKO_IMG = VISITED_HANKO
export const VISITED_HANKO_BLUE_IMG = VISITED_HANKO_BLUE
export const NOT_VISITED_HANKO_IMG = NOT_VISITED_HANKO
export const PLAY_IMG = PLAY
export const PAUSE_IMG = PAUSE
export const CHECK_IMG = CHECK
export const CROSS_IMG = CROSS_MARK
export const ALTERNATE_IMG = ADD_PHOTO_ALTERNATE
export const ALTERNATE_DISABLED_IMG = ADD_PHOTO_ALTERNATE_DISABLED
export const EDIT_IMG = EDIT
export const EDIT_WHITE_IMG = EDIT_WHITE
export const SHOW_MOMENT_IMG = SHOW_MOMENT
export const BACK_IMG = BACK
export const MAP_ICON_WHITE_IMG = MAP_ICON_WHITE
export const AUDIO_ICON_IMG = AUDIO_ICON

// 店舗タイプを表すアイコン
export const SHOP_ICON_IMG = SHOP_ICON
export const WATCH_PLAY_ICON_IMG = WATCH_PLAY_ICON
export const ICON_BUY_RED_ICON_IMG = ICON_BUY_RED_ICON

// ハンバーガーメニュー内のアイコン
export const HAMBURGER_MENU_IMG = HAMBURGER_MENU
export const DOCS_ICON_IMG = DOCS_ICON
export const HELP_ICON_IMG = HELP_ICON
export const HOME_ICON_IMG = HOME_ICON
export const MAP_ICON_IMG = MAP_ICON
export const PRIVACY_POLICY_ICON_IMG = PRIVACY_POLICY_ICON
export const LOGOUT_IMG = LOGOUT
export const EXTERNAL_LINK_ICON_IMG = EXTERNAL_LINK_ICON

//マイページ探訪記と訪問記録がない時の画像
export const NO_SHOWMOMENT_IMG = NO_SHOWMOMENT
export const NO_VISITRECORD_IMG = NO_VISITRECORD
export const PUSH_STAMP_VISIT_IMG = PUSH_STAMP_VISIT

//金沢区イベントページ
export const KANAZAWAKU_THUMBNAIL_IMG = KANAZAWAKU_THUMBNAIL
export const EVENTPAGE_COURSE_TITLE_BACKGROUND_IMG = EVENTPAGE_COURSE_TITLE_BACKGROUND
export const EVENTPAGE_VISITED1_IMG = EVENTPAGE_VISITED1
export const EVENTPAGE_VISITED2_IMG = EVENTPAGE_VISITED2
export const EVENTPAGE_VISITED3_IMG = EVENTPAGE_VISITED3
export const OPEN_KANAZAWA_EVENT_IMG = OPEN_KANAZAWA_EVENT
export const BEFORE_KANAZAWA_EVENT_IMG = BEFORE_KANAZAWA_EVENT

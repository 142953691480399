<template>
  <div class="mx-3 mt-5 pt-3 mb-3 pb-3">
    <BaseBackIcon />
    <!-- サブカテゴリが存在する場合は、サブカテゴリ一覧を表示する -->
    <div v-if="isHasOtherSpot">
      <Tab :currentRouterId="$route.params.id" :shopData="shops" :historicData="historicData" />
    </div>
    <!-- サブカテゴリが存在しない場合はスポット一覧を表示する。 -->
    <div v-else>
      <div v-if="$route.params.id === categoryDesc.kanazanwa.id">
        <img :src="this.categoryImg" alt="カテゴリサムネイル画像" width="100%" />
        <div class="CF_body-sub about-event-title">コースについて</div>
        <p class="CF_body-sub about-text" v-html="categoryDesc.kanazanwa.about"></p>
        <!-- <img
          :src="OPEN_KANAZAWA_EVENT_IMG"
          @click="moveEventPage"
          alt="金沢区イベントページ"
          class="move-event-img"
        /> -->
      </div>
      <router-link
        v-for="subCategory in subCategories"
        :key="subCategory.id"
        :to="{
          name: 'SubCategory',
          params: {
            categoryId: $route.params.id,
            subCategoryId: subCategory.id,
          },
        }"
        class="position-relative"
      >
        <img
          :src="subCategory.photoURL"
          class="sub-category-img w-100 rounded-3 mb-3"
          oncontextmenu="return false"
        />
        <div class="text-white h4 position-absolute top-50 start-50 translate-middle">
          {{ subCategory.name }}
        </div>
      </router-link>
      <div v-if="$route.params.id === categoryDesc.kanazanwa.id">
        <p class="CF_body-main description" v-html="categoryDesc.kanazanwa.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import Tab from '../components/parts/tab/Tab.vue'
import { categoryDesc } from '../constants/const'

export default {
  components: {
    Tab,
  },
  data() {
    return {
      subCategories: [],
      shops: [],
      historicData: [],
      isHasOtherSpot: false,
      categoryDesc: categoryDesc,
      categoryImg: '',
    }
  },
  async created() {
    const firestore = firebase.firestore()
    const categoryRef = firestore.collection('categories').doc(this.$route.params.id)
    const subCategoriesRef = categoryRef.collection('subCategories')

    // categoriesの中にあるsubCategoriesのdocsを取得
    this.subCategories = await subCategoriesRef.get().then((snapshot) =>
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    )

    this.categoryImg = await categoryRef.get().then((doc) => doc.data().photoURL)
    const orderList = await categoryRef.get().then((doc) => doc.data()?.order)
    if (orderList) {
      this.subCategories = this.orderedSubCategory(orderList)
    }

    // カテゴリに紐づいている史跡以外のスポットIDを取得
    const shopIds = await this.getShopIds(categoryRef)

    // 史跡以外のスポットを持っているカテゴリの場合
    if (this.isHasOtherSpot) {
      this.shops = await this.filterShopData(shopIds)
    }
    // 史跡以外のスポットとサブカテゴリを持っているカテゴリの場合
    if (this.isHasOtherSpot && !!this.subCategories.length) {
      this.historicData = await this.filterHistoricData(categoryRef)
    }
  },
  methods: {
    async getShopIds(categoryRef) {
      const firestore = firebase.firestore()
      const shopsCategorizationRef = firestore.collection('shopsCategorization')

      return await categoryRef.get().then(async (snapshot) => {
        this.isHasOtherSpot = !!snapshot.data().spotTypes
        return await shopsCategorizationRef
          .where('categoryId', '==', snapshot.id)
          .get()
          .then(async (snapshot) => await snapshot.docs.map((doc) => doc.data().shopId))
      })
    },
    async filterHistoricData(categoryRef) {
      const categorizationRef = categoryRef.collection('categorization')

      // 史跡データで検索をかけるために、カテゴリが持っているhistoricSiteIdを取得
      const historicSiteIds = await categorizationRef.get().then((snapshot) => {
        return snapshot.docs.map((doc) => doc.data().historicSiteId)
      })
      return await this.$store.state.historicSites.filter((history) => {
        if (historicSiteIds.includes(history.id)) {
          history['shopTypesId'] = 'I0YRd3LYijRBiMWvlHFZ' // 史跡ID。タブで仕分けするため史跡のIDを付与
          return history
        }
      })
    },
    async filterShopData(shopIds) {
      return await this.$store.state.shops.filter((doc) => shopIds.includes(doc.id))
    },
    orderedSubCategory(orderList) {
      return orderList.map((order) => {
        return this.subCategories.find((category) => category.id === order ?? '')
      })
    },
    moveEventPage() {
      this.$router.push({ path: '/event-page' })
    },
  },
}
</script>

<style scoped lang="scss">
.sub-category-img {
  height: 120px;
  object-fit: cover;
  filter: grayscale(30%);
}
.about-text,
.description {
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
}
.about-text {
  margin: 0 8px;
}
.about-event-title {
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $kanazawaku;
}
.move-event-img {
  width: 100%;
  margin: 24px 0;
  border-radius: 4px;
  border: 1px solid $border-light;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
</style>
